import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

class Breadcrumb extends React.Component {

  state = {
    mobileScreen: false
  };

  componentWillMount () {
    const mql = matchMedia("screen and (max-width: 500px)");
    this.setState({
      mobileScreen: mql.matches
    });
  }

  render () {

    const props = this.props;

    let elements = props.slugs.map((element, idx, ary) => {

      const slugs = ary.slice(0, ary.indexOf(element) + 1);

      if (element === ary[ary.length-1]) {
        return (
          <div className='breadcrumb-item' key='end-path'>
            <span>{element.name}</span>
          </div>
        );
      }

      return (
        <div className='breadcrumb-item' key={element.slug||`missing-slug-${idx}`}>
          <span>
            <Link to={`/guide/${slugs.map(s => s.slug).join('--')}`}>
              {element.name}
            </Link>
          </span>
        </div>
      );
    });

    const startIdx = this.state.mobileScreen && props.slugs.length > 2 ?
      (elements.length - 2) : 0;

    if (startIdx > 0) {
      elements = elements.slice(startIdx);
      elements.unshift(
        <div className='breadcrumb-item' key="fill">
          <span>
            ...
          </span>
        </div>
      )
    }

    if (elements.length > 0) {
      elements.unshift(
        <div className='breadcrumb-item' key='root'>
          <span>
            <Link to='/guide'>Start</Link>
          </span>
        </div>
      );
    }

    return (
      <div className='breadcrumb'>
        {elements}
      </div>
    );
  }
}

Breadcrumb.propTypes = {
  slugs: PropTypes.array.isRequired
};

export default Breadcrumb;
