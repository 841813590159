import React, {Component} from 'react';
import Category from './category';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchCategory} from '../../redux/actions';

class Container extends Component {

  componentWillMount () {
    const {key} = this.props.match.params;
    this.props.fetchCategory(key);
  }

  render () {
    return <Category history={this.props.history} category={this.props.categoryState.category} />
  }
}

const CategoryConnected = connect( state => {
  return { categoryState: state.category };
}, dispatch => {
  return bindActionCreators({
    fetchCategory
  }, dispatch)
})(Container);

export default CategoryConnected;
