import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import {Smileys} from './smileys';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {sendFeedback, fetchCrumb, clearFeedbackState} from '../../redux/actions';

const styles = {
  flex: {
    display: 'flex',
    color: 'white'
  },
  flexChild: {
    flexGrow: 1,
    textAlign: 'center',
    padding: '1em 0',
    cursor: 'pointer'
  },
  textarea: {
    width: '98%',
    fontSize: '1.2em',
    padding: '10px 0 0 10px',
    height: '100px',
    border: '1px solid #CCC',
    borderRadius: '5px',
    resize: 'none'
  },
  btn: {
    width: '100%',
    border: '1px solid #ccc',
    textTransform: 'uppercase',
    padding: '10px 0',
    fontSize: '1.2em',
    fontWeight: 600,
    borderRadius: '5px',
    WebkitFontSmoothing: 'subpixel-antialiased',
    backgroundColor: '#eaeaea',
    cursor: 'pointer',
    margin: '10px 0 0'
  }
};

const FooterElement = ({show, onClick, text}) => {

  if (!show) {
    return null;
  }

  return (
    <footer>
      <div style={styles.flex}>

        <div style={styles.flexChild} onClick={e => onClick(e,1)}>
          {text}
        </div>
      </div>
    </footer>
  )
};

class Footer extends Component {

  state = {currentDialog: null, activeSmiley: null, textInput: ''}

  componentDidMount () {
    this.props.fetchCrumb();
  }

  clearState () {
    this.setState({
      currentDialog: null, activeSmiley: null, textInput: '', error: null
    })
  }

  componentDidUpdate (prevProps, prevState) {}

  render () {

    const translation = this.props.translation;

    const template = (
      <div>
        <p>
          {translation.help_us_improve}
        </p>
        <div style={{margin: '20px 0'}}>
          <Smileys onChange={value => this.setState({activeSmiley: value})} active={this.state.activeSmiley} />
        </div>

        {((err) => {
          if (!err) {
            return null;
          }
          return <div style={{textAlign: 'center', color: 'red'}}>{err}</div>
        })(this.state.error)}


        <div style={{margin:'20px 0 0'}}>
          <textarea value={this.state.textInput} onChange={e => {
            this.setState({
              textInput: e.target.value
            });
          }} style={styles.textarea} />
        </div>
        <div>
          <button style={styles.btn} onClick={e => {
            if (this.state.activeSmiley) {
              this.setState({error: null})
              this.props.sendFeedback(this.props.session, {
                rating: this.state.activeSmiley,
                text: this.state.textInput
              })
            } else {
              this.setState({error: translation.feedback_error});
            }
          }}>{translation.send}</button>
          <button style={styles.btn} onClick={e => {
            this.clearState()

          }}>{translation.cancel}</button>
        </div>
      </div>
    );

    return (
      <div>
        <FooterElement show={true} onClick={(e,idx) => this.setState({ currentDialog: idx})} text={translation.give_message}/>
        <Dialog title={translation.give_us_a_message} modal={false} open={this.state.currentDialog === 1} onRequestClose={e => {
          this.props.clearFeedbackState();
          this.clearState();
        }}>
          {!this.props.feedback.created ? template : (
            <div>
              <h2>{translation.thank_you_for_giving_us_feedback}!</h2>
              <div>
                <button style={styles.btn} onClick={e => {
                  this.clearState()
                  this.props.clearFeedbackState()
                }}>{translation.close}</button>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    )
  }
}

export default connect((state, ownProps) => {
  return { feedback: state.feedback, session: state.session };
}, dispatch => {
  return bindActionCreators({
    sendFeedback,
    clearFeedbackState,
    fetchCrumb
  }, dispatch)
})(Footer);
