import React from 'react';
import {Header} from './header';
import {Attribute} from './attribute';
import {MftImage} from '../../common/product-image';

export const Products = ({products}) => {
  return products.filter(p => p.get('picture_link')).map(product => {
    return (
      <div className='product' key={product.get('_key')}>

        <Header text={product.get('nobb_varebeskrivelse')} />

        <div className='product-texts'>
          <Attribute name="Varenummer" value={product.get('varenummer')} />
          <Attribute name="Lang beskrivelse" value={product.get('nobb_lang_varetekst')} />
        </div>
        <div className='product-image'>
          <MftImage pictureLink={product.get('picture_link')} style={{}} limit={2} />
        </div>
      </div>
    )
  }).toList();
};
