import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ProductComponent} from './product';
import I from 'immutable';
import './styles.css';

class BranchProducts extends Component {
  render () {

    if (!this.props.node) {
      return null;
    }

    const products = I.fromJS(this.props.products || []);

    return (
      <div className="branch-products">
        {products.map(product => {
          return <ProductComponent key={product.get('_id')} product={product} />
        })}
      </div>
    );
  }
}

BranchProducts.propTypes = {
  node: PropTypes.object
};

export default BranchProducts
