
const prefix = '/api/v1';

export const fetchProduct = id => dispatch => {
  fetch(`${prefix}/products/${id}`)
  .then(res => res.json())
  .then(res => {
    if (!res.product) {
      return dispatch({
        type: 'PRODUCT_NOT_FOUND'
      });
    }
    return dispatch({
      type: 'FETCH_PRODUCT',
      product: res.product
    });
  })
};

export const clearProducts = () => {
  return {
    type: 'CLEAR_PRODUCTS'
  }
};

export const destroyProduct = (session, id) => dispatch => {

  const options = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    }
  };

  fetch(`${prefix}/products/${id}`, options)
  .then(res => {
    return dispatch({
      type: 'DESTROY_PRODUCT'
    });
  });
};

export const fetchProductsByKeys = (keys) => dispatch => {
  fetch(`${prefix}/products/by-keys?keys=${keys.join(',')}`)
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_PRODUCTS_BY_KEYS',
      products: res.products
    });
  })
};

export const clearProductsByKeys = () => {
  return {
    type: 'CLEAR_PRODUCTS_BY_KEYS'
  }
};

export const fetchProductsLikeVarenummer = (varenummer,lang) => dispatch => {
  fetch(`${prefix}/products/varenummer/${varenummer}?lang=${lang}`)
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_PRODUCTS',
      products: res.products
    });
  })
};

export const productSearch = searchString => dispatch => {
  fetch(`${prefix}/products/search?term=${searchString}`)
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_PRODUCTS',
      products: res.products
    });
  });
};

export const putProduct = (session, key, product) => dispatch => {

  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify(product)
  };

  fetch(`${prefix}/products/${key}`, options)
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'PUT_PRODUCT',
      product: res.product
    });
  })
};

export const clearProductImport = () => {
  return {
    type: 'PRODUCT_IMPORT_CLEAR'
  }
};

export const productImport = (session, lang, form) => dispatch => {

  dispatch({
    type: 'PRODUCT_IMPORT_BEGIN'
  });

  fetch(`${prefix}/products/import/${lang}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: form
  })
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'PRODUCT_IMPORT_END',
      products: res.products
    });
  });
};
