
const normalize = (lang) => {
  if (!lang) {
    return 'no'
  }
  return lang.toLowerCase();
};

const initialState = {
  name: normalize(localStorage.getItem('language')),
  translation: {}
};

const language = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      localStorage.setItem('language', normalize(action.language));
      return { ...state , name: normalize(action.language) };

    case 'FETCH_LANGUAGE':
      return {...state, translation: action.translation }

    case 'FETCH_LANGUAGE_BASELINE_BEGIN':
      return { ...state, fetchLanguageBaselineAt: action.fetchLanguageBaselineAt };

    case 'FETCH_LANGUAGE_BASELINE_SUCCESS':
      return { ...state, baseline: action.baseline,
        fetchLanguageBaselineAt: null };

    case 'UPDATE_LANGUAGE_SUCCESS':
      return { ...state, translation: action.translation };

    default:
      return state;
  }
}

export default language;
