import React from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ProductImport from './product-import';
import {productImport, clearProductImport} from '../../../redux/actions'

class ProductImportContainer extends React.Component {
  render () {
    return <ProductImport {...this.props} />
  }
}

const ProductImportConnected = connect((state) => {
  return {
    session: state.session,
    importedProducts: state.product.importedProducts,
    importInProgress: state.product.importInProgress
  };
}, dispatch => {
  return bindActionCreators({
    productImport,
    clearProductImport
  }, dispatch)
})(ProductImportContainer)

export default ProductImportConnected;
