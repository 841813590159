import React, {Component} from 'react';
import PropTypes from 'prop-types';
import No from './assets/no.svg'
import Se from './assets/se.svg'
import Da from './assets/dk.svg'
import Fi from './assets/fi.svg'
import './styles.css';

const klass = (lang, _lang, open) => {
  const base = `language language-${lang}`
  if (open) {
    return `${base} open`
  }
  if (lang === _lang) {
    return `${base} active`;
  }
  return base;
};

export class SelectLang extends Component {

  componentWillMount () {
    const {lang} = this.props;
    this.setState({ lang: lang || 'no', open: false });
  }

  componentWillReceiveProps (nextProps) {
    const {lang} = nextProps;
    this.setState({ lang });
  }

  clickHandler (lang) {
    const open = this.state.open;
    this.setState({ open: !open});
    if (open) {
      this.props.onSetLang(lang);
    }
  }

  render () {
    const clickHandler = this.clickHandler.bind(this);
    const {lang,open} = this.state;
    return (
      <div className='languages'>
        <div className={klass('no', lang, open)}>
          <img src={No} alt='Norway' onClick={() => clickHandler('no')} />
        </div>
        <div className={klass('se', lang, open)}>
          <img src={Se} alt='Sweden' onClick={() => clickHandler('se')} />
        </div>
        <div className={klass('dk', lang, open)}>
          <img src={Da} alt='Denmark' onClick={() => clickHandler('dk')} />
        </div>
        <div className={klass('fi', lang, open)}>
          <img src={Fi} alt='Finland' onClick={() => clickHandler('fi')} />
        </div>
      </div>
    );
  }
}

SelectLang.propTypes = {
  onSetLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired
};

export default class Language extends Component {

  setLang (lang) {
    this.props.setLanguage(lang);
  }

  render () {
    return <SelectLang lang={this.props.language.name}
      onSetLang={ lang => this.setLang(lang) } />
  }
}
