import React from 'react';
import { Link} from 'react-router-dom';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';

export default class LeftMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {open: false};
  }

  render() {
    return (
      <div>
        <Drawer docked={false} open={this.props.open}
          width={300}
          onRequestChange={(open) => this.props.onRequestChange(open)}>
          <div style={{fontWeight: '600', margin: '2em 1em'}}>
            {this.props.translation.menu}
          </div>
          <MenuItem>
            <Link onClick={e => this.props.onRequestChange(false)} to="/guide">
              {this.props.translation.guide}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={e => this.props.onRequestChange(false)} to="/search">
              {this.props.translation.search_for_products}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={e => this.props.onRequestChange(false)} to="/admin">
              {this.props.translation.administration}
            </Link>
          </MenuItem>
        </Drawer>
      </div>
    );
  }
}
