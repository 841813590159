import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {MiniButton} from '../guide/widgets';

export default class Instructions extends Component {

  componentWillMount () {
    this.setState({ instructionKey: '' });
  }

  render () {

    const {instructions} = this.props;

    const actions = [
      <FlatButton
        label="Avbryt"
        primary={true}
        onClick={e => this.setState({ instructionKey: '' })}
      />,
      <FlatButton
        label="Slett nå"
        primary={true}
        onClick={e => {
          this.setState({ instructionKey: '' })
          this.props.deleteInstruction(this.state.instructionKey)
        }}
      />,
    ];

    return (

      <div>
        <h3>Bruksanvisninger</h3>

        <Link style={{marginBottom: '1em'}} className='btn btn-primary' to='/admin/instructions/new'>
          <MiniButton>+ Ny bruksanvising</MiniButton>
        </Link>

        <table className='table'>
          <thead>
            <tr>
              <th>Tittel</th>
              <th>Ingress</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {instructions.map(i => {
              return (
                <tr key={i.get('_key')}>
                  <td>
                    <Link to={`/admin/instructions/get/${i.get('_key')}`}>{i.get('title') || 'Ingen tittel'}</Link>
                  </td>
                  <td>{i.get('ingress')}</td>
                  <td>
                    <MiniButton type='alert' onClick={e => this.setState({ instructionKey: i.get('_key')})}>
                      Slett
                    </MiniButton>
                  </td>
                </tr>
              );
            }).toList()}
          </tbody>
        </table>
        <Dialog
          title={`Vil du virkelig slette?`}
          actions={actions}
          modal={true}
          open={this.state.instructionKey ? true : false}
          onRequestClose={this.handleClose}>

        </Dialog>
      </div>
    )
  }
}
