
/**
* parses a search string
*
* @return {object} - {foo: 'bar'}
*/
export const parseSearch = (s) => {
  if (typeof s !== 'string') {
    throw new Error('argument must be string');
  }
  return s.split("&")
  .map(str => str.split("="))
  .map(pair => pair.map(item => item.replace("?","")))
  .reduce((acc,cur) => {
    return Object.assign(acc, {
      [cur[0]]: cur[1]
    })
  }, {});
}
