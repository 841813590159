import React, {Component} from 'react';
import {fromJS} from 'immutable';
import {UploadIcon} from './upload-icon';
import {IconList} from './icon-list';
import {$tr} from './utils';

export default class StyleCreator extends Component {

  state = { icon: fromJS({}) };

  componentWillMount () {
    this.props.getIcons();
  }

  render () {

    return (
      <div className="style-creator">
        <h3>
          {$tr(this.props.language, 'button_styles')}
        </h3>

        <UploadIcon icon={this.state.icon} language={this.props.language}
          validFileTypes={["image/png", "image/jpg"]}
          onClose={e => {
            this.setState({ openUploadDialog: false })
          }}
          onChange={icon => {
            this.setState({ icon });
          }} onIconFileUpload={(file, orientation, title) => {
            this.setState({ icon: fromJS({})});
            const form = new FormData();
            form.append('file', file);
            form.append('orientation', orientation);
            form.append('title', title);
            this.props.uploadIconImage(this.props.session, this.props.language.name, form);
          }} />

        <IconList language={this.props.language} icons={this.props.icon.icons} onIconDestroyRequest={key => {
          this.props.destroyIcon(this.props.session, key);
        }} onIconEditRequest={_icon => {
          const icon = fromJS({
            _key: _icon._key,
            title: _icon.title,
            orientation: _icon.orientation
          });
          this.setState({ icon });
        }}/>

      </div>
    )
  }
}
