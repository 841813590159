
const guide = (state = {}, action) => {

  switch (action.type) {

    case 'FETCH_GUIDE':
      return { ...state, guide: action.guide };

    case 'FETCH_GUIDE_NOT_FOUND':
      const guide = { content: { body: 'Ny guide', header: 'Ny guide', info: 'Ny guide info'},
        lang: action.language};
      return { ...state, guide };

    case 'FETCH_GUIDE_ERROR':
      return state;

    case 'SAVE_GUIDE':
      return { ...state, guide: action.guide };

    case 'EDIT_NODE':
      return { ...state, path: action.path };

    case 'EDIT_NODE_DONE':
      return { ...state, path: null };

    default:
      return state;
  }
}

export default guide
