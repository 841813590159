import React from 'react';
import {config} from '../../common/config';

export const IconElement = ({icon}) => {
  if (!icon) return null;
  const url = config.s3BasePath + icon.filename;
  return (
    <div className='branch-icon'>
      <img src={url} alt='branch-icon' />
    </div>
  );
};
