import React from 'react'
import I from 'immutable';
import {TreeNode} from './tree-node';
import EditNode from './edit-node';
import {pickNode,mapTreeProp} from '../../guide/tree-utils';
import './style.css';

export default class Guide extends React.Component {

  updateGuide (node) {

    // if we have a root content node, we do not address 'branches'
    const path = I.List(this.props.guide.path).interpose('branches')
    .update(list => {
      return list.size > 0 ? list.unshift('branches') : list
    });

    const content = I.fromJS(this.props.guide.guide.content).setIn(path, node);

    const iGuide = I.fromJS(this.props.guide)
    .setIn(['guide', 'content'], content)
    .deleteIn(['guide', 'createdAt']);

    this.props.saveGuide(
      this.props.session,
      iGuide.toJS()
    );
    this.props.editNodeDone();
  }

  componentWillUnmount () {
    this.props.editNodeDone();
  }

  render () {

    const { guide, path } = this.props.guide;
    const {instructions} = this.props.instruction;

    if (!guide) {
      return <div>Fetching Guide</div>
    }

    if (path) {

      const node = pickNode(guide.content, path);
      const header = mapTreeProp(guide.content, path, 'name').join(' -- ') || 'Forsiden';

      return (
        <EditNode header={header} showIconUpload={path.length>0} node={node}
          language={this.props.language} icons={this.props.icons}
          instructions={instructions} key={guide.createdAt}
          onIconFileUpload={(file,iconOrientation) => {
            const form = new FormData();
            form.append('file', file);
            form.append('path', this.props.guide.path.join('/'));
            form.append('orientation', iconOrientation);
            this.props.uploadIconImage(this.props.session, this.props.language.name, form);
          }} onDone={node => {
            this.updateGuide(node)
          }} products={this.props.product.products} onVarenummer={vn => {
            if (vn === 'CLEAR') {
              this.props.clearProducts();
            } else {
              this.props.fetchProductsLikeVarenummer(vn, this.props.language.name);
            }
          }} onAbort={ _ => {
            this.props.editNodeDone();
          }} />
      )
    }

    return (
      <div>
        <TreeNode
          editNode={this.props.editNode}
          path={[]}
          node={guide.content} level={0} />
      </div>
    );
  }
}
