
/**
* Traverse a tree along prop names in path.
* If tree  { tre: { branches: { inne: { branches: { skruer: {}}}}}}
* to access 'skruer' the path items must be ['tre', 'inne', 'skruer']
*/
export const pickNode = (node, path, acc = []) => {
  const p = path[0];
  if (!p) {
    return node;
  }
  if (!node.branches) {
    return node;
  }
  if (!node.branches[p]) {
    throw new Error(`path '/${acc.concat(p).join('/')}' was not found`);
  }
  return pickNode(node.branches[p], path.slice(1), acc.concat(p));
};


/**
* Traverse tree structure. When we find the object we're looking for,
* callback with 'path'.
*/
export const pathTo = (tree, apple, path = [], callback) => {
  if (typeof tree !== 'object') { return; }
  if (tree === apple) {
    callback(path);
  }
  const keys = Object.keys(tree);
  keys.map(key => {
    if (key === 'branches') {
      return pathTo(tree[key], apple, path, callback);
    }
    return pathTo(tree[key], apple, path.concat(key), callback);
  });
};

export const findByPath = (tree, path) => {
  const p = path[0];
  if (!p) return tree;
  const branch = tree[p];
  if (branch) {
    return findByPath(branch.branches, path.slice(1));
  }
};

export const mapTreeProp = (tree, path, propName) => {
  const pathDup = path.slice(0);
  const res = pathDup.map((p,i,ary) => {
    const branch = pickNode(tree, ary.slice(0, i+1))
    return branch[propName];
  })
  return res;
};
