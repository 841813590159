import React from 'react';
import {Link} from 'react-router-dom';
import {List} from 'immutable';
import {parseSearch} from '../../utils';
import {S3_BASE_URL} from '../../common/product-image';

const styles = {
  idxContainer: {
    position: 'relative',
    textAlign: 'center',
    margin: '1em 0'
  },
  hr: {
    position: 'absolute',
    width: '100%',
    top: '20px',
    zIndex: '-1',
    boxSizing: 'border-box'
  },
  idx: {
    padding: '.5em .8em',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '50%',
    backgroundColor: 'white',
    fontSize: '20pt',
    fontWeight: '200'
  }
};

export const BackLink = ({search}) => {
  if (!search.back) {
    return null;
  }
  return (
    <div className='back-btn'>
      <Link to={search.back}>Tilbake</Link>
    </div>
  )
};

export default class Instruction extends React.Component {

  componentWillMount () {
    this.search = {};
    if (this.props.location.search) {
      this.search = parseSearch(this.props.location.search)
    }
  }

  render () {

    const instruction = this.props.instruction;
    const steps = instruction.get('steps') || List();

    return (
      <div>
        <h3>{instruction.get('title')}</h3>

        <BackLink search={this.search} />

        <div>
          {steps.map((step,idx) => {
            return (
              <div key={step.get('id')} style={{margin: '1em 0'}}>

                <div style={styles.idxContainer}>
                  <span style={styles.idx}>{idx+1}</span>
                  <hr style={styles.hr} />
                </div>

                <div style={{display: 'flex'}}>
                  <div style={{flex: '1', textAlign: 'right', paddingRight: '1em'}}>
                    <img style={{width: '50%'}} src={`${S3_BASE_URL}${step.getIn(['image', 'filename'])}`} alt="instruction-step" />
                  </div>
                  <div style={{flex: '1', paddingLeft: '1em'}}>
                    {step.get('ingress')}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
