
import React from 'react';

export const _debounce = function (fn, ms) {
  let _id;
  return function (e) {
    clearTimeout(_id);
    const value = e.target.value;
    _id = setTimeout(function () {
      fn(value);
    }, ms);
  }
};

export const VarenummerSearch = (props) => {

  const onChange = _debounce(value => {
    if (value.length >= props.minLength) {
      props.onChange(value);
    } else {
      props.onChange();
    }
  }, 400);

  return (
    <div className="field" style={props.style}>
      <label htmlFor="varenummer">
        Søk etter varenummer eller tekst for å legge til produkter
      </label>
      <input type="search" id="varenummer" name="varenummer" onFocus={e => {
        if (props.onFocus && e.target.value) {
          props.onFocus(e.target.value);
        }
      }} onChange={onChange} />
    </div>
  )
};
