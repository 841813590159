const I = require('immutable');

const initialState = {
  instructions: I.List()
};

const Step = I.Record({
  ingress: '',
  image: {}
});

const Instruction = I.Record({
  title: '',
  ingress: '',
  lang: 'no',
  steps: I.List(),
  createdAt: '',
  updatedAt: '',
  _key: '',
  _id: '',
  _rev: '',
  _oldRev: ''
});

const instruction = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_LANGUAGE':
      return { ...state, language: action.language };

    case 'INSTRUCTION_ERROR':
      return { ...state, error: action.error };

    case 'FETCH_INSTRUCTIONS':
      return { ...state, instructions: I.fromJS(action.instructions), instruction: null };

    case 'INSTRUCTION_ADD_STEP':
      return { ...state, instruction: I.fromJS(action.instruction) }

    case 'DELETE_INSTRUCTION':
      const idx = state.instructions.findIndex(i => i.get('_key') === action.key);
      return { ...state, instructions: state.instructions.delete(idx)};

    case 'FETCH_INSTRUCTION':
      return { ...state, instruction: Instruction(I.fromJS(action.instruction)) };

    case 'NEW_INSTRUCTION':
      return { ...state, instruction: Instruction() };

    case 'NEW_INSTRUCTION_STEP':
      const steps = state.instruction.get('steps').push(Step());
      return { ...state, instruction: state.instruction.set('steps', steps) };

    case 'SAVE_INSTRUCTION':
      return {
        ...state,
        instruction: Instruction(I.fromJS(action.instruction))
      };

    case 'UPLOAD_INSTRUCTION_STEP_IMAGE':
      return { ...state, instruction: I.fromJS(action.instruction)}

    case 'UPDATE_INSTRUCTION':

      const updatedInstruction = action.instruction
        .set('_oldRev', action._oldRev)
        .set('_rev', action._rev);
      return {
        ...state,
        instruction: updatedInstruction
      };

    default:
      return state;
  }
}

export default instruction;
