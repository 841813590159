
import React from 'react';
import {Link} from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import {MftImage} from '../../../common/product-image';

const _debounce = function (fn, ms) {
  let _id;
  return function (e) {
    clearTimeout(_id);
    const value = e.target.value;
    _id = setTimeout(function () {
      fn(value);
    }, ms);
  }
};

export const ProductImage = ({src}) => {
  const style = {
    img: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    missing: {
      width: '50px',
      height: '50px',
      backgroundColor: '#ccc'
    }
  };

  if (!src) {
    return (
      <Tooltip arrow={true}
        html={
          <div>Mangler bilde</div>
        }>
        <div style={style.missing}></div>
      </Tooltip>
    )
  }

  return (
    <Tooltip arrow={true}
      html={
        <img style={style.img} src={src} alt='product' />
      }>
      <img style={style.img} src={src} alt='product' />
    </Tooltip>
  )
};

export default class Product extends React.Component {

  onSearch (value) {
    if (value) {
      this.props.fetchProductsLikeVarenummer(value, this.props.language.name);
    }
  }

  render () {

    const onSearch = _debounce(value => {
      if (value.length >= 3) {
        this.onSearch(value);
      } else {
        // quick and dirty to get empty result list
        this.onSearch('bogus search');
      }
    }, 400);

    const style = {
      product: {
        float:'left',
        margin: '10px',
        padding: '10px',
        fontSize: '9pt',
        fontWeight: 200,
        backgroundColor: '#eee',
        border: '1px solid #aaa',
        borderRadius: '4px'
      },
      image: {
        width: '50px',
        height: '50px'
      }
    };

    return (
      <div className='products'>
        <h3>Produkter</h3>

        <div style={{width: '400px'}}>
          <div className='field'>
            <label htmlFor='search'>Søk etter produkter</label>
            <input type="text" name='search' id='search' onChange={onSearch} />
          </div>
        </div>

        <div className='result'>
          {this.props.products.map(product => {
            return (
              <div key={product.get('_key')} style={style.product}>
                <div style={style.image}>
                  <MftImage pictureLink={product.get('picture_link')} style={{
                      maxWidth: '100%',
                      maxHeight: '100%'
                    }} />
                </div>
                <div>
                  <Link to={`/admin/product/${product.get('_key')}`}>{product.get('varenummer')}</Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
