import React from 'react'
import {Link} from 'react-router-dom';
import {List} from 'immutable';

export default class BranchInstructions extends React.Component {
  render () {
    const {instructions, node} = this.props;
    if (!(node && node.instructionKeys)) {
      return null;
    }

    const instructionKeys = List(node.instructionKeys);

    const currentInstructions = instructions.filter(i => {
      return instructionKeys.includes(i.get('_key'))
    });

    if (currentInstructions.size < 1) {
      return null;
    }

    const pathname = this.props.pathname;

    return (
      <div className='instructions-links'>

        <h3>Bruksanvisninger</h3>

        {currentInstructions.map(i => {
          return (
            <div className='instruction-link' key={i.get('_id')}>
              <Link to={{
                pathname: `/instruction/${i.get('_key')}`,
                search: pathname ? ('?back=' + pathname) : ''
              }}>{i.get('title')}</Link>
            </div>
          )
        })}
      </div>
    );
  }
}
