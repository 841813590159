
import React from 'react';
import {Link} from 'react-router-dom';
import RaisedButton from 'material-ui/RaisedButton';
import {MftImage} from '../../../common/product-image';
import I from 'immutable';
import './styles.css'

const updateableProductProps = [
  "lang",
  "forpakningsdetaljer",
  "skruelengde",
  "stammediameter",
  "gipslag",
  "sportype",
  "skruehode",
  "skruedimensjon",
  "overflatebelegg",
  "nobb_varebeskrivelse",
  "materiale",
  "nobb_lang_varetekst"
];

const names = {
  "skruelengde": "Skruelengde",
  "skruedimensjon": "Skruedimensjon",
  "gipslag": "Gipslag",
  "skruehode": "Skruehode",
  "stammediameter": "Stammediameter",
  "sportype": "Sportype",
  "materiale": "Materiale",
  "overflatebelegg": "Overflatebelegg"
};


export const InputField = ({product, attr, onChange, onBlur, label, type = 'input'}) => {

  const labelText = label || names[attr] || attr;

  const textElement = type === 'input' ? (
    <input type="text" id={attr} name={attr} value={product.get(attr)||''}
      onChange={e => onChange(e)} onBlur={e => onBlur(e)} />
  ) : (
    <textarea id={attr} cols="30" rows="5"
      name={attr}
      onChange={e => onChange(e)}
      onBlur={e => onBlur(e)}
      value={product.get(attr)} />
  );
  return (
    <div className='field' key={attr}>
      <label htmlFor={attr}>{labelText}</label>
      {textElement}
    </div>
  );
};

export const basename = (url, ext) => {
  const last = url.split('/').pop();
  return last.replace(ext, '');
};

export const getProductImages = (urls) => {
  if (!urls) {
    return [];
  }
  return urls.split(', ').map(image => {
    return image;
  });
};

export const Images = ({product, style}) => {
  return <MftImage
    pictureLink={product.get('picture_link')}
    limit={10} />
};

export const ProductHeader = ({product, className}) => {
  return (
    <h2 className={className}>
      <span>Varenummer: </span>
      {product.get('varenummer')}
    </h2>
  )
};

class Product extends React.Component {

  componentWillMount () {
    this.setState({ product: null });
    const {id} = this.props.match.params;
    this.props.fetchProduct(id)
  }

  componentWillReceiveProps (props) {
    const {product} = props.product;
    if (!this.state.product && product) {
      this.setState({ product });
    }
  }

  changeHandler (e) {
    const product = this.state.product;
    this.setState({
      product: product.set(e.target.name, e.target.value)
    });
  }

  onBlurHandler (e) {
    const product = {};
    updateableProductProps.forEach(prop => {
      product[prop] = this.state.product.get(prop);
    });
    const key = this.state.product.get('_key');
    this.props.putProduct(this.props.session, key, product);
  }

  render () {

    const {product} = this.state;

    if (this.props.product.error && this.props.product.error === 'not-found') {
      return (
        <div>
          Kunne ikke finne produktet {this.props.match.params.id}
       </div>
      )
    }

    if (!product) {
      return <div>Venter på produkt ... </div>
    }

    const languages = I.Map({
      'no': 'Norsk',
      'se': 'Svensk',
      'dk': 'Dansk',
      'fi': 'Finsk'
    });

    const onBlurHandler = this.onBlurHandler.bind(this);
    const changeHandler = this.changeHandler.bind(this);

    return (
      <div className='product'>

        <ProductHeader className='varenummer' product={product} />

        <div style={{margin: '1em 0'}}>
          <Link to="/admin/products"> Tilbake </Link>
        </div>

        <div className='product-form'>

          <div style={{marginBottom: '1em'}}>
            Språk: <span style={{fontWeight: 'bold'}}>{languages.get(product.get('lang'))}</span>
          </div>

          <InputField type='textarea' attr='nobb_varebeskrivelse' label="Varebeskrivelse"
            product={product} onChange={e => changeHandler(e)} onBlur={e => onBlurHandler(e)} />

          <InputField type='textarea' attr='nobb_lang_varetekst' label="Varebeskrivelse lang"
            product={product} onChange={e => changeHandler(e)} onBlur={e => onBlurHandler(e)} />

        </div>

        <div className='product-images'>
          <Images product={product} />
        </div>
        <div>
          <RaisedButton label="Slett" backgroundColor="#E21324" labelColor="#FFF" onClick={e => {
              this.props.destroyProduct(this.props.session, product.get('_key'))
              this.props.history.push('/admin/products');
            }} />
        </div>
      </div>
    );
  }
}

export default Product
