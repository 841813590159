const prefix  = '/api/v1';

export const fetchGuide = (language) => dispatch => {
  return fetch(`${prefix}/guide/${language}`)
    .then(res => res.json())
    .then(res => {
      if (res.guide) {
        dispatch({
          type: 'FETCH_GUIDE', guide: res.guide
        });
      } else {
        dispatch({
          type: 'FETCH_GUIDE_NOT_FOUND',
          error: 'Could not find any guide',
          language
        });
      }
    })
    .catch(err => dispatch({
      type: 'FETCH_GUIDE_ERROR', error: err.message
    }));
};

export const saveGuide = (session, guide) => dispatch => {

  return fetch(`${prefix}/guide/${guide.guide.lang}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify(guide)
  })
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'SAVE_GUIDE', guide: res.guide
    })
  });
};

export const editNode = (path) => {
  return {
    type: 'EDIT_NODE',
    path
  }
};

export const editNodeDone = () => {
  return {
    type: 'EDIT_NODE_DONE'
  }
};
