import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CircularProgress from 'material-ui/CircularProgress';
import Skeleton from '../skeleton';
import GuideRoot from './guide-root';
import BranchProducts from './branch-products';
import BranchInstructions from './branch-instructions';

import './styles.css';

class Guide extends Component {
  render () {

    const {guide, path, node, instructions, products } = this.props;

    if (!guide) {
      return (
        <Skeleton translation={this.props.translation}>
          <section>
            <div style={{textAlign: 'center'}}>
              <CircularProgress size={60} thickness={7} />
              <div>Fetching guide</div>
            </div>
          </section>
        </Skeleton>
      );
    }

    if (!node && path.length > 0) {
      return (
        <Skeleton translation={this.props.translation}>
          <section className='guide'>
            <h3>Something is wrong</h3>
            <p>
              <Link to="/guide"> Go back </Link>
            </p>
          </section>
        </Skeleton>
      );
    }
    return (
      <Skeleton translation={this.props.translation}>
        <section className='guide'>
          <GuideRoot guide={guide.content} path={path} />
          <BranchProducts node={node} products={products} />
          <BranchInstructions pathname={this.props.pathname} node={node} instructions={instructions} />
        </section>
      </Skeleton>
    );
  }
}

export default Guide;
