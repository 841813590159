
/**
* Translation based on language definitions
* @example 
* $tr(language, 'test', { downcase: true });
*/
export const $tr = (language, key, options = {}) => {
  const tr = language.translation[key] || language.baseline[key];
  if (options.downcase) {
    return tr.toLowerCase();
  }
  return tr;
};
