
import React, {Component} from 'react';
import {EditInfoBox} from './edit-info-box';
import Checkbox from 'material-ui/Checkbox';
import FlatButton from 'material-ui/FlatButton';
import infoIcon from '../../../../assets/info-icon.svg';

export class EditNodeBranches extends Component {

  handleToggleInfoboxShowButton (key) {
    const {node} = this.props;
    const show_button = node.getIn(['branches', key, 'infobox', 'show_button']);
    this.props.onChange(
      node.setIn(['branches', key, 'infobox', 'show_button'], !show_button)
    );
  }

  handleInfoBoxChange (key, infobox) {
    const {node} = this.props;
    this.props.onChange(
      node.setIn(['branches', key, 'infobox'], infobox)
    );
  }

  handleChange (key, branch) {
    const {node} = this.props;
    this.props.onChange(
      node.setIn(['branches', key], branch)
    );
  }

  render () {

    const {node} = this.props;

    if (!node.has('branches')) {
      return null;
    }

    const branches = node.get('branches');

    const rows = branches.map((branch, key) => {

      return (
        <div key={`branch-${key}`} >
          <div className="edit-node-branch row">
            <div className='name col'>
              <div className='field'>
                <input type="text" name={`${key}-name`} defaultValue={branch.get('name')}
                  onChange={e => {
                    this.handleChange(key, branch.set('name', e.target.value));
                  }} />
              </div>
            </div>
            <div className='info col'>
              <div className='field'>
                <input type="text" name={`${key}-helptext`} defaultValue={branch.get('helptext')}
                  onChange={e => {
                    this.handleChange(key, branch.set('helptext', e.target.value));
                  }} />
              </div>
            </div>
            <div className='info col'>
              <div className='field'>
                <input type="text" name={`${key}-info`} defaultValue={branch.get('info')}
                  onChange={e => {
                    this.handleChange(key, branch.set('info', e.target.value));
                  }} />
              </div>
            </div>
            <div className='infobox col'>
              <div className='field' style={{padding: '.3em'}}>
                <Checkbox label="Vis infoikon?" checked={branch.getIn(['infobox','show_button'])}
                  onCheck={e => {
                    this.handleToggleInfoboxShowButton(key);
                  }} />
              </div>
            </div>
            <div className='col'>
              <FlatButton label="Slett" style={{color: 'rgb(228, 11, 24)'}} primary={true} onClick={e => {
                let _node = node.deleteIn(['branches', key]);
                _node = _node.get('branches').size < 1 ? _node.delete('branches') : _node
                this.props.onChange(_node)
              }} />
            </div>
          </div>
          <EditInfoBox infobox={branch.update((b) => {
            return b.has('infobox') ? b.get('infobox') : b.clear()
          })} onChange={infobox => {
            this.handleInfoBoxChange(key, infobox);
          }} />
        </div>
      )
    });

    return (
      <div className='edit-node-branches'>
        <h2>Knapper til undersider</h2>
        <div className='row'>
          <div className='col'>
            <div className='header'>Tekst</div>
            <div className='sub-header'>Hovedtekst på knappen</div>
          </div>
          <div className='col'>
            <div className='header'>Hjelpetekst</div>
            <div className='sub-header'>Vises med liten skrift under tittel.</div>
          </div>
          <div className='col'>
            <div className='header'>Infotekst</div>
            <div className='sub-header'>Vises på høyre side.</div>
          </div>
          <div className='col'>
            <div className='header'>
              Infoikon
              <img src={infoIcon} alt="info-ikon" style={{width:'15px', height: '15px'}}/>
            </div>
            <div className='sub-header'>Infoknapp med hjelpetekst?</div>
          </div>
          <div className='col'>
          </div>
        </div>
        {rows.toList()}
      </div>
    );
  }
}
