import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import CategoryCollection from './categories';
import Category from './category';
import Product from './product';
import Products from './products';
import Guide from './guide';
import Instructions from './instructions';
import ProductImport from './product-import';
import Skeleton from '../skeleton';
import Translation from './translation';
import Feedbacks from './feedbacks';
import StyleCreator from './style-creator';

import './styles.css';

const style = {
  userInfo: {
    padding: '1em 0',
    fontSize: '10pt',
    textAlign: 'right'
  },
  container: {
    padding: '0 1em'
  }
};

const componentLoader = Komponent => ownProps => routerProps => {
  return <Komponent {...ownProps} />
};

const feedbacksLoader = componentLoader(Feedbacks);

class Admin extends Component {

  componentWillMount () {
    this.props.fetchFeedbacks(this.props.session);
  }

  render () {
    const user = this.props.session.user;
    const {translation} = this.props.language
    return (
      <Skeleton translation={translation}>
        <div style={style.container}>
          <div style={style.userInfo}>
            <div>{translation.logged_in_as}: {user && user.name}</div>
          </div>
          <div>
            <div className='nav-bar'>
              <div className='nav-item'>
                <Link to="/admin">{translation.guide}</Link>
              </div>
              <div className='nav-item'>
                <Link to="/admin/products">{translation.products}</Link>
              </div>
              <div className='nav-item'>
                <Link to="/admin/instructions/all">{translation.manuals}</Link>
              </div>
              <div className='nav-item'>
                <Link to="/admin/product-import">{translation.product_import}</Link>
              </div>
              <div className='nav-item'>
                <Link to="/admin/translation">{translation.translation}</Link>
              </div>
              <div className='nav-item'>
                <Link to="/admin/feedbacks">{translation.feedbacks}</Link>
              </div>
              <div className='nav-item'>
                <Link to="/admin/style-creator">Knappe-stiler (css)</Link>
              </div>
            </div>
            <div className="routes">
              <Route exact path="/admin" component={Guide} />
              <Route path="/admin/category/:id" component={Category} />
              <Route path="/admin/products" component={Products} />
              <Route path="/admin/product/:id" component={Product} />
              <Route path="/admin/categories" component={CategoryCollection} />
              <Route path="/admin/instructions/all" component={Instructions} />
              <Route path="/admin/instructions/get/:id" component={Instructions} />
              <Route path="/admin/instructions/new" component={Instructions} />
              <Route path="/admin/product-import" component={ProductImport} />
              <Route path="/admin/translation" component={componentLoader(Translation)(this.props)} />
              <Route path="/admin/feedbacks" component={
                feedbacksLoader({
                  feedbacks: this.props.feedback.feedbacks || [],
                  deleteFeedback: this.props.deleteFeedback(this.props.session),
                })
              } />
              <Route path="/admin/style-creator" component={StyleCreator} />
            </div>
          </div>
        </div>
      </Skeleton>
    )
  }
}

export default Admin;
