import React from 'react';
import MissingImage from '../assets/fallback.png';

export const S3_BASE_URL = '//s3-eu-west-1.amazonaws.com/mft-festeguiden/';

export const S3Url = (str) => {
  if (!str) {
    return null;
  }
  const urls = str.split(', ')
  .filter(url => /.*-b\.eps$/.test(url));

  const url = urls[0];
  if (!url) {
    return null;
  }
  const _url = url.split('/').pop().split('.eps').shift() + '.jpg';
  return S3_BASE_URL + _url;
};

export const imageUrl = (url) => {
  if (/\.eps$/.test(url)) {
    return S3_BASE_URL + url.split('/').pop().split('.eps').shift() + '.jpg';
  }
  return url;
};

export const imageUrls = (urls='') => {
  return urls.split(',')
  .map(u => u.trim())
  .filter(url => url)
  .map(url => imageUrl(url));
};

export const mftPictureLinkUrls = (pictureLink, idx = 0) => {
  if (!pictureLink) {
    return [];
  }
  if (idx < 0) {
    return pictureLink.split(', ');
  }
  return pictureLink.split(', ')[idx];
};

export const MftImage = ({pictureLink, style, limit=1}) => {

  const urls = imageUrls(pictureLink);

  const images = urls
  .map(url => {
    if (/\.(jpg|png)$/.test(url)) {
      return <img key={url} src={url} alt='product' style={style} />
    }
    return <img key={url} src={S3Url(url)} alt='product' style={style} />
  });

  if (images.length < 1) {
    return <img src={MissingImage} style={style} alt='missing' />;
  }

  return images.slice(0,limit);
};

export const ProductImage = ({ image, style, fallbackText }) => {

  if (!image) {
    return <span>{fallbackText||'n/a'}</span>
  }
  const _s3url = S3Url(image);
  if (!_s3url) return null;
  return (<img src={_s3url} alt="product" style={style} />);
};
