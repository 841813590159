import React, {Component} from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import infoIcon from '../../assets/info-icon.svg';

export default class InfoBox extends Component {

  componentWillMount () {
    this.setState({ open: false })
  }

  render () {

    const {infobox} = this.props;
    if (!(infobox && infobox.show_button)) {
      return null;
    }

    const actions = [
      <FlatButton label="Lukk" primary={true} onClick={e => this.setState({open: false})}/>
    ];

    return (
      <div className='info-icon' onClick={e => {
        this.setState({ open: true });
        e.preventDefault();
      }}>
        <img src={infoIcon} alt='info-icon' role="button" />
        <Dialog title={infobox.header} modal={false} open={this.state.open}
          actions={actions}
          onRequestClose={e => {
            this.setState({ open: false})
          }}>
          {infobox.body}
        </Dialog>
      </div>
    );
  }
}
