import React, {Component} from 'react'
import PropTypes from 'prop-types';

export class InputField extends Component {

  onChange (evt) {
    this.props.onChange(evt.target.value);
  }

  render () {
    const value = this.props.value || '';
    return (
      <div style={this.props.style} className="input-field field">
        <label className="label">
          {this.props.label}
        </label>
        <input className="input-field-text" value={value} type="text"
          onChange={this.onChange.bind(this)} />
      </div>
    )
  }
}

InputField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}
