import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Authenticate from '../authenticate';
import {logout, fetchLanguage, fetchLanguageBaseline,
  updateLanguage, fetchFeedbacks, deleteFeedback} from '../../redux/actions';
import Admin from './admin'

const Connected = connect((state, ownProps) => {
  return { session: state.session, language: state.language, feedback: state.feedback }
}, dispatch => {
  return bindActionCreators({
    logout,
    fetchFeedbacks,
    deleteFeedback,
    fetchLanguage,
    fetchLanguageBaseline,
    updateLanguage
  }, dispatch)
})(props => {

  if(!props.language.translation._id) {
    props.fetchLanguage(props.language.name);
  }
  if(!(props.language.baseline || props.language.fetchLanguageBaselineAt)) {
    props.fetchLanguageBaseline();
  }
  return <Authenticate><Admin {...props} /></Authenticate>
});

const Wrap = Auth => props => {
  return <Connected {...props} />
};

export default Wrap(Authenticate);
