import React, {Component} from 'react';

export class EditInfoBox extends Component {

  onChange (e) {
    this.props.onChange(
      this.props.infobox.set(e.target.name,e.target.value)
    );
  }

  render () {
    const infobox = this.props.infobox;
    if (!infobox.get('show_button')) {
      return null;
    }
    const onChange = this.onChange.bind(this);
    return (
      <div className='infobox-edit'>
        <h3>
          Infoboks (vises når man trykker på infoikonet)
        </h3>
        <div className="field">
          <label>Overskrift</label>
          <input type="text" name='header' defaultValue={infobox.get('header')} onChange={onChange} />
        </div>
        <div className="field">
          <label>Tekst</label>
          <textarea type="textarea" name='body' defaultValue={infobox.get('body')} onChange={onChange} />
        </div>
      </div>
    )
  }
};
