
const prefix = '/api/v1';

export const fetchInstructions = (lang) => (dispatch, getState) => {
  fetch(`${prefix}/instructions?lang=${lang}`)
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_INSTRUCTIONS',
      instructions: res.instructions
    });
  })
};

export const newInstruction = () => {
  return {
    type: 'NEW_INSTRUCTION'
  }
};

export const newInstructionStep = () => {
  return {
    type: 'NEW_INSTRUCTION_STEP'
  }
};

export const updateInstruction = (session, instruction) => dispatch => {

  fetch(`${prefix}/instructions/${instruction.get('_key')}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify({
      instruction: instruction.delete('_key')
    })
  })
  .then(res => res.json())
  .then( res => {
    if (res.error) {
      return dispatch({
        type: 'INSTRUCTION_ERROR',
        error: res
      })
    }
    return dispatch({
      type: 'UPDATE_INSTRUCTION',
      _oldRev: res._oldRev,
      _rev: res._rev,
      instruction
    })
  });
};

export const saveInstruction = (session, instruction) => dispatch => {

  fetch(`${prefix}/instructions`,{
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify({
      instruction: instruction.delete('_key')
    })
  })
  .then(res => res.json())
  .then( res => {
    return dispatch({
      type: 'SAVE_INSTRUCTION',
      instruction: res.instruction
    })
  });
};

export const uploadInstructionStepImage = (session, form) => dispatch => {

  fetch(`${prefix}/instructions/file-upload`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: form
  })
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'UPLOAD_INSTRUCTION_STEP_IMAGE',
      instruction: res.instruction
    });
  });
};

export const fetchInstruction = (key) => dispatch => {
  fetch(`${prefix}/instructions/${key}`)
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_INSTRUCTION',
      instruction: res.instruction
    });
  });
};

export const moveInstructionStep = (session, key, stepId, offset) => dispatch => {

  const url = offset === -1 ?
    `${prefix}/instructions/${key}/up/${stepId}` :
    `${prefix}/instructions/${key}/down/${stepId}`;

  fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    }
  })
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_INSTRUCTION',
      instruction: res.instruction
    })
  });
};

export const deleteInstruction = (session, key) => dispatch => {
  fetch(`${prefix}/instructions/${key}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    }
  }).then(emptyRes => {
    return dispatch({
      type: 'DELETE_INSTRUCTION',
      key
    });
  });
};

export const deleteInstructionStep = (session, key, stepId) => dispatch => {
  '/instructions/{key}/drop-step/{stepId}'
  fetch(`${prefix}/instructions/${key}/drop-step/${stepId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    }
  })
  .then(res => res.json())
  .then(res => {
    console.log(res);
  });
};

export const addInstructionStep = (session, key, ingress) => dispatch => {
  fetch(`${prefix}/instructions/${key}/add-step`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify({
      ingress
    })
  })
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'INSTRUCTION_ADD_STEP',
      instruction: res.instruction
    });
  });
};
