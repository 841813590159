
import React, {Component} from 'react';
import {$tr} from '../../../../../common/utils';
import {config} from '../../../../../common/config';

export class IconTable extends Component {
  render () {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Bilde</th>
            <th>Tittel</th>
            <th>Orientering</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.icons.map(icon => {
            return (
              <tr key={icon._key}>
                <td>
                  <img style={{maxWidth: '150px'}} src={[config.s3BasePath, icon.filename].join("")} alt="product-icon" />
                </td>
                <td>{icon.title}</td>
                <td>{$tr(this.props.language, icon.orientation)}</td>
                <td>
                  {((node) => {
                    if (node.getIn(['icon','imageKey']) === icon._key) {
                      return (
                        <span className="checkmark-before" style={{cursor: 'pointer'}} onClick={e => {
                          this.props.onChange(node.delete('icon'))
                        }}>
                          Dette ikonet er valgt
                        </span>
                      )
                    } else {
                      return (
                        <span style={{cursor: 'pointer'}} onClick={e => {
                          node = node.setIn(['icon','filename'], icon.filename)
                          node = node.setIn(['icon','imageKey'], icon._key)
                          node = node.deleteIn(['icon','iconKey']);
                          node = node.setIn(['icon','orientation'], icon.orientation)
                          this.props.onChange(node)
                        }}>
                          Velg dette ikonet
                        </span>
                      )
                    }
                  })(this.props.node)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
}
