import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {IconElement} from './icon-element';
import InfoBox from './info-box';
import PropTypes from 'prop-types';
import './branch-it.css';

const klass = ((branch) => {
  let classNames = ['branch-button'].concat(branch.css);
  if (branch.icon) {
    classNames = classNames.concat('with-icon');
    if (branch.icon.orientation) {
      classNames = classNames.concat('with-icon-' + branch.icon.orientation);
    }
  }
  return classNames.join(' ');
});

const getOrientation = (icon) => {
  if (!(icon && icon.orientation)) {
    return null;
  }
  return icon.orientation;
};

class Branch extends Component {
  render () {

    const {url, branch} = this.props;
    const {icon} = branch;

    let elements = [
      <div key="branch-name-and-helptext" className="branch-name-and-helptext">
        <div className='branch-name'>{branch.name}</div>
        <div className='branch-helptext'>{branch.helptext}</div>
        <InfoBox key="info-box" infobox={branch.infobox} />
      </div>
    ];

    if (branch.info) {
      elements.push(
        <div key="branch-info" className='branch-info'>{branch.info}</div>
      )
    }

    const orientation = getOrientation(icon);

    if (orientation === 'left') {
      elements.unshift(<IconElement key="branch-icon" icon={branch.icon} />)
    } else if (orientation === 'right') {
      elements.push(<IconElement key="branch-icon" icon={branch.icon} />)
    }

    return (
      <Link to={url} role='button' className='branch-button-link'>
        <div className={klass(this.props.branch)}>
          {elements}
        </div>
      </Link>
    )
  }
}

Branch.propTypes = {
  branch: PropTypes.object.isRequired
};

export default Branch;
