const defaultState = {
  icon: null,
  icons: []
};

const icon = (state = defaultState, action) => {

  switch (action.type) {

    case 'UPLOAD_ICON_IMAGE_SUCCESS':
      return { ...state, icon: action.icon };

    case 'GET_ICONS_SUCCESS':
      return { ...state, icons: action.icons };

    case 'CLEAR_ICON':
      return { ...state, icon: null };

    case 'REMOVE_ICON_FROM_ICONS':
      return { ...state, icons: action.icons };

    default:
      return state;
  }
}

export default icon;
