
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setLanguage} from '../../redux/actions';
import Language from './language';

const LanguageContainer = connect( (state,ownProps) => {
  return { language: state.language };
}, (dispatch) => {
  return bindActionCreators({
    setLanguage
  }, dispatch)
})(Language);

export default LanguageContainer;
