import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import CircularProgress from 'material-ui/CircularProgress';
import Skeleton from '../skeleton';
import {fetchInstruction, fetchLanguage} from '../../redux/actions';
import Instruction from './instruction';
import './styles.css';

class InstructionContainer extends Component {

  componentWillMount () {
    const key = this.props.match.params.key;
    this.props.fetchInstruction(key);
  }

  render () {

    const instruction = this.props.instruction.instruction;

    if (!instruction) {
      return (
        <Skeleton translation={this.props.language.translation}>
          <section className='instruction'>
            <div>Henter bruksanvisninger</div>
            <CircularProgress size={80} thickness={5} />
          </section>
        </Skeleton>
      )
    }

    return (
      <Skeleton translation={this.props.language.translation}>
        <section className='instruction'>
          <Instruction location={this.props.location} instruction={instruction} />
        </section>
      </Skeleton>
    )
  }
}

const InstructionConnected = connect( (state, ownProps) => {
  const {instruction, language} = state;
  return { language, instruction };
}, dispatch => {
  return bindActionCreators({
    fetchInstruction,
    fetchLanguage
  }, dispatch)
})(props => {
  if(!props.language.translation._id) {
    props.fetchLanguage(props.language.name);
  }
  return <InstructionContainer {...props} />
});

export default InstructionConnected;
