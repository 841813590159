
import React from 'react';
import Avatar from 'material-ui/Avatar';
import {Ellipsis} from './ellipsis';
import {imageUrls} from '../../../../common/product-image';

export const ProductList = ({ products, onSelect }) => {
  return (
    <ul className='product-list-dropdown'>
      {products.map(product => {
        return (
          <li key={product.get('_id')} onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onSelect(product)
          }}>
            <Avatar size={32} src={imageUrls(product.get('picture_link'))[0]} />
            <span className='value'>{product.get('varenummer')}</span>
            <Ellipsis className='value' text={product.get('nobb_lang_varetekst')}
              maxLength={80} />
          </li>
        )
      })}
    </ul>
  )
};
