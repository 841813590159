import React from 'react';

export const Header = ({text}) => {
  if (!text) {
    return null;
  }
  const _text = text.split('.')[0];

  return (
    <div className='header'>
      <h3>{_text}</h3>
    </div>
  );
};
