/**
 * Author Thomas Eng - Drive Nordic
 */

// Add polyfills
import 'core-js/fn/set';
import 'core-js/fn/map';
import 'core-js/fn/object';
import 'core-js/fn/promise';
import 'whatwg-fetch';

// Add app deps
import React from 'react';
import {render} from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import MftApp from './app';
import mftApp from './redux/reducers';

const middleware = applyMiddleware(thunkMiddleware);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  mftApp,
  composeEnhancers(middleware)
);

render(
  <Provider store={store}>
    <MftApp appName="MFT Festeguiden" />
  </Provider>,
  document.getElementById('root')
);
