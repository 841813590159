import React, {Component} from 'react';
import Instructions from './instructions';
import Instruction from './instruction';

export default class InstructionsContainer extends Component {

  actionType () {
    const match = this.props.match;
    if (match.url.match(/new$/)) {
      return 'new';
    } else if (match.url.match(/all$/)) {
      return 'fetch-all';
    } else if (match.params.id) {
      return 'fetch-one';
    }
    return 'unknown';
  }

  componentWillReceiveProps (nextProps) {
    if(this.props.instruction.language !== nextProps.instruction.language) {
      this.props.fetchInstructions(nextProps.instruction.language);
    }
  }

  componentDidMount () {

    switch(this.actionType()) {

      case 'new':
        this.props.newInstruction();
        break;

      case 'fetch-one':
        this.props.fetchInstruction(this.props.match.params.id);
        break;

      case 'fetch-all':
        this.props.fetchInstructions(this.props.language)
        break;

      default:
        break;
    }
  }

  render () {
    if (this.actionType() === 'fetch-all') {
      return <Instructions instructions={this.props.instruction.instructions}
        deleteInstruction={key => {
          this.props.deleteInstruction(this.props.session, key)
        }} />
    }
    return <Instruction session={this.props.session}
      saveInstruction={this.props.saveInstruction}
      addInstructionStep={this.props.addInstructionStep}
      newInstructionStep={this.props.newInstructionStep}
      language={this.props.language}
      instruction={this.props.instruction.instruction}
      moveInstructionStep={this.props.moveInstructionStep}
      updateInstruction={this.props.updateInstruction}
      deleteInstructionStep={this.props.deleteInstructionStep}
      uploadInstructionStepImage={this.props.uploadInstructionStepImage} />
  }
}
