
import React, {Component} from 'react'
import Im from 'immutable';
import Skeleton from '../skeleton';
import {Products} from './products';
import './styles.css';

const CategoryHeader = ({category}) => {
  const fallback = [category.category1,category.category2,category.category3].join(" ");
  return (
    <div className='category-header'>
      <h2 className='name'>{category.name || fallback}</h2>
      <div className='description'>{category.description}</div>
    </div>
  )
};

export default class Category extends Component {

  render () {
    const {category} = this.props;
    if (!category) {
      return <div>Category not found</div>
    }
    const products = Im.fromJS(category.products);
    return (
      <Skeleton>
        <section className='category'>
          <div className='back-btn'>
            <button onClick={e => {
              this.props.history.goBack();
            }}>{"<<"} Tilbake</button>
          </div>
          <CategoryHeader category={category} />
          <Products products={products} />
        </section>
      </Skeleton>
    );
  }
}
