
import CategoryCollection from './categories'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchCategories} from '../../../redux/actions';

const CategoryCollectionContainer = connect((state) => {
  return { category: state.category };
}, (dispatch) => {
  return bindActionCreators({
    fetchCategories
  }, dispatch)
})(CategoryCollection);

export default CategoryCollectionContainer;
