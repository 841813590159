
const prefix = '/api/v1';

export const fetchCategories = () => dispatch => {
  return fetch(prefix + '/categories/plain')
    .then(res => res.json())
    .then(res => dispatch({
      type: 'FETCH_CATEGORIES', categories: res.categories
    }))
    .catch(err => dispatch({
      type: 'FETCH_CATEGORIES_ERROR', error: err.message
    }));
};

export const fetchCategory = (key) =>  dispatch => {
  return fetch(prefix + '/categories/'+key)
    .then(res => res.json())
    .then(res => dispatch({
      type: 'FETCH_CATEGORY', category: res.category
    }))
    .catch(err => dispatch({
      type: 'FETCH_CATEGORY_ERROR', error: err.message
    }));
};

export const fetchCategoryByToken = (token) =>  dispatch => {
  return fetch(prefix + '/categories/name/'+token)
    .then(res => res.json())
    .then(res => dispatch({
      type: 'FETCH_CATEGORY', category: res.category
    }))
    .catch(err => dispatch({
      type: 'FETCH_CATEGORY_ERROR', error: err.message
    }));
};

export const fetchCategoriesByKeys = (keys) => dispatch => {
  const params = '["' + keys.join('","') + '"]';
  return fetch(`${prefix}/categories/by-keys/${params}`)
  .then(res =>res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_CATEGORIES',
      categories: res.categories
    });
  });
};

export const patchCategory = (session, key, category) => dispatch => {
  return fetch(`${prefix}/categories/${key}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify(category)
  })
  .then(res => res.json())
  .then(res => {
    if (res.error) {
      return dispatch({
        type: 'PATCH_CATEGORY_ERROR', error: res
      })
    }
    return dispatch({
      type: 'PATCH_CATEGORY', category: res.category
    });
  });
};
