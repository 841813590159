import React, {Component} from 'react';
import {config} from '../../../common/config';
import {$tr} from './utils';

export const TableHeader = ({cols}) => {
  return (
    <thead>
      <tr>
        {cols.map((col,idx) => {
          return <th key={idx}>{col}</th>
        })}
      </tr>
    </thead>
  )
};

export const TableBody = ({rows}) => {
  return (
    <tbody>
      {rows.map((row,idx) => {
        return (
          <tr key={idx}>
            {row.map((col, jdx) => {
              return (
                <td key={jdx}>{col}</td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

const style = {
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginRight: '5px'
  }
};

export class IconList extends Component {
  render () {
    return (
      <div>
        <h4>
          {$tr(this.props.language, 'icons')} ({this.props.icons.length})
        </h4>
        <table className="table">
          <TableHeader cols={[
            $tr(this.props.language, 'image'),
            $tr(this.props.language, 'title'),
            $tr(this.props.language, 'orientation'),
            ''
          ]} />
          <TableBody rows={this.props.icons.map(icon => {
            return [<img style={{maxWidth: '150px'}} src={config.s3BasePath + icon.filename} alt="icon" />,
            icon.title, $tr(this.props.language, icon.orientation),
            <div>
              <span style={style.link} onClick={_ => this.props.onIconDestroyRequest(icon._key)}>
                {$tr(this.props.language, 'delete')}
              </span>
            </div>]
          })} />
        </table>
      </div>
    );
  }
}
