import React, {Component} from 'react';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import Im from 'immutable';
import Skeleton from '../skeleton';
import {Products} from '../category/products';
import '../category/styles.css';

const SearchButton = ({ label, displayWhen, onClick }) => {
  if (displayWhen === true) {
    return (
      <div className='search-button' style={{display: 'inline-block'}}>
        <FlatButton label={label} onClick={e => onClick()} />
      </div>
    );
  }
  return null;
};

class Search extends Component {

  componentWillMount () {
    this.setState({ term: null });
  }

  doSearch () {
    if (!this.state.term) {
      return;
    }
    this.setState({ searching: true })
    this.props.productSearch(this.state.term);
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ searching: false });
  }

  render () {
    const translation = this.props.language.translation;
    const products = Im.fromJS(this.props.product.products);
    const term = this.state.term || '';
    return (
      <Skeleton translation={translation}>
        <section className='search'>
          <div className="header">
            <h3>Produktsøk</h3>
          </div>
          <div>
            <TextField hintText="Varenummer, beskrivelse, osv ..."
              floatingLabelText="Søk etter produkter i databasen"
              onChange={(e,val) => this.setState({ term: val}) }
              onBlur={e => this.doSearch()}
            />
            <SearchButton label="Søk nå" displayWhen={term.length > 2}
              onClick={e => e} />
          </div>
          <div className='search-result category'>
            <Products products={products} />
          </div>
        </section>
      </Skeleton>
    )
  }
}

export default Search;
