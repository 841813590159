
const session = (state = {}, action) => {
  switch (action.type) {

    case 'FETCH_CRUMB':
      return { ...state, crumb: action.crumb };

    case 'AUTHENTICATED':
      return { ...state, token: action.token, error: undefined }

    case 'AUTHENTICATED_ERROR':
      return { ...state, error: action.error };

    case 'FETCH_TOKEN':
      return { ...state, token: action.token };

    case 'FETCH_USER_BEGIN':
      return {...state, fetchingUserAt: Date.now() }

    case 'FETCH_USER_SUCCESS':
      return { ...state, user: action.user, fetchingUserAt: null };

    case 'FETCH_USER_ERROR':
      return { ...state, error: action.error, token: undefined, fetchingUserAt: null }

    case 'LOGGED_OUT':
      return {};

    default:
      return state
  }
}

export default session
