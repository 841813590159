
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import Product from './product';
import {fetchProduct, putProduct, destroyProduct} from '../../../redux/actions'

const ProductContainer = connect((state) => {
  return { product: state.product, session: state.session,
    language: state.language };
}, dispatch => {
  return bindActionCreators({
    destroyProduct,
    fetchProduct,
    putProduct
  }, dispatch)
})(Product)

export default ProductContainer;
