import React from 'react';
import classNames from 'classnames';

const Pill = ({text, onClose, className }) => {
  const klass = classNames(['pill', className])
  return <div className={klass}>
    <span className='pill pill-text'>{text}</span>
    <span className='pill pill-close-icon' onClick={onClose}>✖</span>
  </div>
};

const MiniButton = ({onClick, className, children, type='default', size=10}) => {

  const types = {
    alert: 'mini-button-red'
  };

  const klass = classNames(['mini-button', className, types[type]]);
  const style = {fontSize: `${size}pt`}
  return (
    <div className={klass} onClick={onClick} style={style}>
      {children}
    </div>
  )
};

const ToolTip = ({children, className}) => {

  return <div className='tooltip'>{children}</div>
};

export { Pill, MiniButton, ToolTip };
