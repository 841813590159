/* @flow */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from './breadcrumb';
import {BranchElements} from './branch-elements';
import {mapTreeProp, pickNode} from './tree-utils';

/**
* GuideRoot component.
* Renders a 'guide' tree.
* In outer branches, will have list of products.
*/
class GuideRoot extends Component {

  mapTreeSlugs (path) {
    const names = mapTreeProp(this.props.guide, path, 'name');
    return names.map((name, idx) => {
      return {
        slug: this.props.path[idx],
        name
      }
    });
  }

  componentWillReceiveProps (nextProps) {
    try {
      const currentNode = pickNode(this.props.guide, nextProps.path);
      const slugs = this.mapTreeSlugs(nextProps.path)
      this.setState({ currentNode, slugs });
    } catch (err) {
      this.setState({ currentNode: null, slugs: [] });
    }
  }

  componentWillMount () {
    const tree = this.props.guide;
    const currentNode = pickNode(tree, this.props.path);
    const slugs = this.mapTreeSlugs(this.props.path)
    this.setState({ currentNode, slugs });
  }

  render () {
    const node = this.state.currentNode;
    return (
      <div>
        <Breadcrumb slugs={this.state.slugs} />
        <div className='branch-header'>
          <h1>{node.header}</h1>
          <p> {node.body} </p>
        </div>
        <BranchElements branches={node.branches} path={this.props.path} />
      </div>
    );
  }
}

GuideRoot.propTypes = {
  guide: PropTypes.object.isRequired,
  path: PropTypes.array.isRequired
};

export default GuideRoot;
