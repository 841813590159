import React from 'react'
import './styles.css';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import classnames from 'classnames';

export default class ProductImport extends React.Component {

  componentWillMount () {
    this.setState({
      lang: null,
      errorMessage: ''
    })
  }

  submit (e) {
    e.preventDefault();
    if (!this.refs.importForm[0].value.length) {
      this.setState({ errorMessage: 'Du må velge ett språk for importen'})
      return false;
    }
    if (!this.refs.importForm[1].files.length) {
      this.setState({ errorMessage: 'Du må velge en excel fil å importere'})
      return false;
    }
    this.setState({ errorMessage: ''});

    const form = new FormData();
    form.append('file', this.refs.importForm[1].files[0]);

    this.props.productImport(
      this.props.session,
      this.refs.importForm[0].value,
      form
    );
    return false;
  }

  render () {

    if (this.props.importInProgress) {
      return (
        <div style={{width: '90%', margin: '100px auto 0'}}>
          <p> Laster opp og oppdaterer. Kryss fingrene og lag deg en kaffe.</p>
          <CircularProgress />
        </div>
      )
    }

    if (this.props.importedProducts.size > 0) {
      return (
        <div className='product-import'>
          <h3>Import ble utført</h3>
          <div>
            <FlatButton onClick={e => this.props.clearProductImport()}>
              Ja takk, ferdig med det.
            </FlatButton>
          </div>
          <div>
            {this.props.importedProducts.map((product, idx) => {
              return (
                <span className='varenummer' key={product.get('varenummer')}>
                  {product.get('varenummer')}
                </span>
              );
            })}
          </div>
        </div>
      )
    }

    return (
      <div className='product-import'>

        <h3>Produktimport</h3>

        <p>
          Vær oppmerksom på at denne importen vil overskrive alle produkter som
          befinner seg i databasen med samme varenummmer
        </p>

        <p className={classnames({
          error: this.state.errorMessage.length
        })}>
          {this.state.errorMessage}
        </p>

        <form method="post" encType="multipart/form-data" ref='importForm' onSubmit={e => this.submit(e)}>

          <div className="field">
            <label htmlFor="lang">Velg språk for denne importen</label>
            <select name="lang" ref='lang' onChange={e => this.setState({
              lang: e.target.value
            })}>
              <option value=''>Velg ett språk</option>
              <option value="no">Norsk</option>
              <option value="se">Svensk</option>
              <option value="dk">Dansk</option>
              <option value="fi">Finsk</option>
            </select>
          </div>

          <div className="field">
            <label htmlFor="file">Velg en excel fil som inneholder produktdata</label>
            <input type="file" name="file" />
          </div>
          <div className="actions">
            <button type="submit">Lagre</button>
          </div>
        </form>
      </div>
    );
  }
}
