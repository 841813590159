import React from 'react';
import {Link} from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import {muiTheme} from './theme-settings'
import LeftMenu from '../left-menu';
import Language from '../language';
import Footer from '../footer';
import MftLogoWhite from '../../assets/mft-logo-white.svg';
import HomeIcon from '../../assets/home.svg';

class Skeleton extends React.Component {
  componentWillMount () {
    this.setState({
      openDrawer: false
    })
  }

  toggleDrawer (isOpen) {
    this.setState({openDrawer: isOpen})
  }

  render () {
    const translation = this.props.translation || {};
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div style={{marginBottom: '5em'}}>
          <AppBar
            title={
              <div style={{textAlign: 'center', paddingTop: '4px'}}>
                <Link to="/" style={{color:'white', textDecoration: 'none', fontSize: '0.8em'}}>
                  <img src={HomeIcon} alt='home' /> {translation.home}
                </Link>
              </div>
            }
            iconElementRight={
              <Link to="/guide">
                <img style={{marginTop:'15px'}} src={MftLogoWhite} alt='logo' />
              </Link>
            }
            onLeftIconButtonClick={e => this.toggleDrawer(true)} />
          <LeftMenu translation={translation} open={this.state.openDrawer}
            onRequestChange={open => this.toggleDrawer(open)} />
          <div style={{float: 'right'}}>
            <Language />
          </div>
          <div style={{clear: 'both'}}></div>
          <div className='container'>
            {this.props.children}
          </div>
          <Footer translation={translation} />
        </div>
      </MuiThemeProvider>
    )
  }
}

export default Skeleton;
