import React from 'react';
import {Link} from 'react-router-dom';
import bigInfo from '../../assets/big-info.svg';
import bigLogo from '../../assets/big-logo.svg';
import './style.css'
import TranslationContext from '../../translation-context';
import VersionComponent from './version';

export default class Splash extends React.Component {
  render () {
    return (
      <TranslationContext.Consumer>
        {translation => (
          <div className='splash-root'>
            <div className='splash'>
              <div>
                <img className='splash-info-logo anim-logo' src={bigInfo} alt="info-logo" />
              </div>
              <div className='splash-button-outer'>
                <Link className='splash-button-inner' to="/guide">
                  {translation.find_right_fastener}
                </Link>
              </div>
              <div className='splash-text'>
                {translation.find_right_fastener_long}
              </div>
              <div className='splash-brand-logo'>
                <img className='splash-info-logo' src={bigLogo} alt="brand-logo" />
              </div>
              <VersionComponent />
            </div>
          </div>
        )}
      </TranslationContext.Consumer>
    )
  }
}
