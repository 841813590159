import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './styles.css'

export const ProductImages = ({ image, style, fallbackText }) => {
  if (!image) {
    return <span>{fallbackText||'n/a'}</span>
  }
  const urls = image.split(', ');
  return urls.map((_url,idx) => {
    const url = _url.split('/').pop().split('.eps').shift() + '.jpg';
    const s3url = '//s3-eu-west-1.amazonaws.com/mft-festeguiden/' + url;
    return <img key={`image-${idx}`} src={s3url} alt="product" style={style} />;
  });
};

export const Products = ({ products }) => {
  return products.map(product => {
    return (
      <div key={product._key} className='category-products'>
        <p>
          Varenummer: <Link to={`/admin/product/${product._key}`}>{product.varenummer}</Link>
        </p>
        <p style={{textDecoration: 'underline'}}>
          {product.nobb_varebeskrivelse}
        </p>
        <p>{product.nobb_lang_varetekst}</p>
        <div>
          <ProductImages image={product.picture_link}
            fallbackText='fant ikke bilde' style={{width: '200px', marginRight: '2px'}} />
        </div>
      </div>
    );
  })
};

export const EditCategory = ({category, onChange, onSave}) => {

  const _onChange = (e) => {
    const propName = e.target.name;
    const value = e.target.value;
    onChange(propName, value);
  };

  return (
    <div style={{maxWidth:'800px'}}>
      <div className='field'>
        <label htmlFor='name'>Kategorinavn</label>
        <input type="text" name='name' defaultValue={category.name} onChange={_onChange}/>
      </div>
      <div className='field'>
        <label htmlFor='description'>Beskrivelse</label>
        <input type="text" name='description' defaultValue={category.description}
          onChange={_onChange}/>
      </div>
      <div className='actions'>
        <button className='button' onClick={onSave}>Lagre</button>
      </div>
    </div>
  )
};

export const ErrorMessage = ({error}) => {
  if (!error) {
    return null;
  }
  return (
    <div style={{color: 'red'}}>
      {error.message} {error.statusCode}
    </div>
  )
};

const Notification = ({message}) => {
  if (!message) {
    return null;
  }
  return (
    <div className='notification'>
      {message}
    </div>
  )
};

export default class Category extends Component {

  onChange (k,v) {
    const category = Object.assign({}, this.props.category, {
      [k]: v
    });
    this.props.onChange(category);
  }

  componentWillMount () {
    this.setState({
      updateMessage: null
    });
  }

  componentWillUnmount () {
    clearTimeout(this.tid);
  }

  componentWillReceiveProps (props) {
    if (this.props.category && props.category._rev !== this.props.category._rev) {
      this.setState({
        updateMessage: 'Kategori ble oppdatert'
      });
      clearTimeout(this.tid);
      this.tid = setTimeout( () => {
        this.setState({ updateMessage: null });
      }, 5000);
    }
  }

  onSave () {
    this.props.onSave(this.props.category);
  }

  render () {

    const category = this.props.category;

    if (!this.props.category) {
      return <div> ... please wait ... </div>
    }

    return (
      <div className='admin-category-page'>
        <h3>Kategori: {category.category1} / {category.category2} / {category.category3}</h3>

        <ErrorMessage error={this.props.error} />

        <Notification message={this.state.updateMessage} />

        <EditCategory category={category} onChange={(k,v) => {
            this.onChange(k,v);
          }} onSave={e => this.onSave()} />

        <div className='number-of-products'>
          Antall produkter: {category.products.length}
        </div>

        <Products products={category.products} />
      </div>
    )
  }
}
