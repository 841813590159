import React,{Component} from 'react';

const VERSION="v0.9.1"

const style = {
  fontSize: '0.6em'
};

export default class VersionComponent extends Component {
  render () {
    return (
      <div>
        <div style={style}>{VERSION}</div>
      </div>
    )
  }
}
