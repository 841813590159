
import React,{Component} from 'react';
import I from 'immutable';
import Dialog from 'material-ui/Dialog';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import {EditNodeBranches} from './edit-node-branches';
import {ProductChips} from '../../../../common/product-chips';
import {ProductList} from './product-list';
import {InstructionSelect} from './instruction-select';
import {IconPicker} from './icon-picker';
import {VarenummerSearch} from './varenummer-search';
import cssClasses from './css-classes';
import './styles.css';

const stripUnwantedChars = (str='') => {
  return str.toLowerCase()
  .replace(/ /g,'')
  .split('').filter(char => {
    return (/[a-z]|-/).test(char);
  }).join('');
};

class EditNode extends Component {

  state = {
    node: I.Map(),
    openDialog: false,
    newBranchNameError: ''
  };

  componentWillMount () {
    const node = I.fromJS(this.props.node)
    this.setState({ node });
  }

  onDone () {
    this.props.onDone(this.state.node);
  }

  onChange (node) {
    this.setState({ node });
  }

  onAbort () {
    this.props.onAbort();
  }

  addNewBranch () {
    const name = stripUnwantedChars(this.state.newBranchName)
    let node = this.state.node;
    if(node.hasIn(['branches', name])) {
      this.setState({ newBranchNameError: 'Du må velge ett annet navn, siden dette allerede eksisterer'})
    } else {
      this.setState({
        newBranchNameError: '',
        openDialog: false,
        node: node.setIn(['branches', name, 'name'], this.state.newBranchName)
      })
    }
  }

  abortAddNewBranch () {
    this.setState({
      newBranchName: '',
      newBranchNameError: '',
      openDialog: false
    });
  }

  addProductToNode (product) {
    const {node} = this.state;
    const products = node.get('products') || I.List()
    const entry = products.findEntry(p => p.get('_id') === product.get('_id'))
    if (entry) { return }
    this.setState({
      node: node.set('products', products.push(product))
    })
  }

  removeProductFromNode (product) {
    const {node} = this.state;
    const products = node.get('products') || I.List()
    const entry = products.findEntry(p => p.get('_id') === product.get('_id'))
    if (!entry) { return }
    const [idx] = entry;
    this.setState({
      node: node.deleteIn(['products', idx])
    });
  }

  render () {

    const { node } = this.state;

    const dialogActions = [
      <FlatButton
        label="Avbryt"
        style={{color: 'red'}}
        primary={true}
        onClick={e => this.abortAddNewBranch()}
      />,
      <FlatButton
        label="Lagre"
        primary={true}
        onClick={e => this.addNewBranch()}
      />
    ];

    return (
      <div onClick={e => {
        if (e.target.id !== 'varenummer' && this.props.products.size > 0) {
          this.props.onVarenummer('CLEAR');
        }
      }} >
        <h3>{this.props.header}</h3>

        <h2>Tekst på denne siden</h2>

        <div className="jumbo-card">
          <div className='field'>
            <label htmlFor='header'>Overskrift</label>
            <input name='header' type='text' id='header' value={node.get('header')||''}
              onChange={e => {
                this.onChange(node.set('header', e.target.value))
              }} />
          </div>

          <div className='field'>
            <label htmlFor='body'>Ingress</label>
            <textarea id='body' name='body' value={node.get('body')||''}
              onChange={e => {
                this.onChange(node.set('body', e.target.value));
              }} />
          </div>

          <div className='field'>
            <label htmlFor='css'>CSS [{node.get('css')}]</label>
            <select id='css' name='css' value={node.get('css')}
              onChange={e => {
                this.onChange(node.set('css', e.target.value));
              }}>
              {cssClasses.map(cssClass => {
                return (
                  <option key={cssClass.get('css')} value={cssClass.get('css')}>
                    {cssClass.get('name')}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        {(() => {
          if (!node.get('products') || node.get('products').size < 1) {
            return null;
          }
          return (
            <div className="jumbo-card">
              <h3>Valgte produkter</h3>
              <ProductChips onDelete={
                product => this.removeProductFromNode(product)
              } products={node.get('products')} />
            </div>
          )
        })()}

        <div className="jumbo-card">
          <VarenummerSearch
            style={{marginBottom:'0'}}
            onChange={value => {
              if (value) {
                return this.props.onVarenummer(value);
              }
              this.props.onVarenummer('CLEAR');
            }}
            onFocus={value => {
              this.props.onVarenummer(value);
            }}
            minLength={2} />
          <ProductList products={this.props.products} onSelect={product => {
            this.addProductToNode(product);
          }} />
        </div>


        <div className='jumbo-card'>
          <h3> Klikk for å legge til undersider: </h3>
          <RaisedButton label="Legg til underside" onClick={e => this.setState({ openDialog: true })} />
        </div>

        <EditNodeBranches node={node} onChange={_node => this.onChange(_node)} />

        <hr />

        <div>

          <h3> Velg knappe ikoner</h3>
          <IconPicker onChange={node=> {
            this.onChange(node);
          }} node={node} language={this.props.language} icons={this.props.icons} />
        </div>

        <hr />

        <div style={{margin: '1em 0'}}>
          <h3>Velg bruksanvisninger</h3>

          <InstructionSelect className='visible-true'
            instructions={this.props.instructions}
            selected={node.get('instructionKeys') || I.List()}
            onChange={(instruction, selected) => {

              const node = this.state.node;

              let keys = (this.state.node.get('instructionKeys') || I.List())

              if (keys.includes(instruction.get('_key'))) {
                this.setState({
                  node: node.set(
                    'instructionKeys',
                    keys.filter(key => key !== instruction.get('_key'))
                  )
                })
              } else {
                this.setState({
                  node: node.set(
                    'instructionKeys',
                    keys.push(instruction.get('_key'))
                  )
                })
              }
            }} />
        </div>

        <RaisedButton label="Ferdig" style={{marginRight: '1em'}} onClick={e => this.onDone()} />
        <RaisedButton label="Avbryt" onClick={e => this.onAbort()} />

        <Dialog
          title="Legg til ny underside"
          actions={dialogActions}
          modal={false}
          open={this.state.openDialog}
          onRequestClose={e => this.abortAddNewBranch()}>
          <div>{this.state.newBranchNameError}</div>
          <div className='field'>
            <label htmlFor='new-branch-name'>Navn</label>
            <input onChange={e =>
              this.setState({newBranchName: e.target.value})
            } type="text" name='new-branch-name' id='new-branch-name' />
          </div>
        </Dialog>
      </div>
    );
  }
}

EditNode.propTypes = {
  node: PropTypes.object.isRequired,
  onVarenummer: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  onIconFileUpload: PropTypes.func.isRequired
};

export default EditNode;
