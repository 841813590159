const prefix  = '/api/v1';

export const sendFeedback = (session, feedback) => dispatch => {

  dispatch({
    type: 'SEND_FEEDBACK_BEGIN'
  });

  return fetch(prefix + '/feedbacks', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify({feedback})
  })
  .then(res => res.json())
  .then(res => {
    dispatch({
      type: 'SEND_FEEDBACK_DONE',
      feedback: res.feedback
    })
  });
}

export const clearFeedbackState = () => {
  return {
    type: 'FEEDBACK_CLEAR'
  }
}

export const deleteFeedback = session => dispatch => key => {

  fetch(prefix + '/feedbacks/' + key, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    }
  })
  .then(res => {
    fetchFeedbacks(session)(dispatch)
  })
};

export const fetchFeedbacks = session => dispatch => {

  dispatch({
    type: 'FETCH_FEEDBACKS_BEGIN'
  });

  return fetch(prefix + '/feedbacks', {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
  })
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_FEEDBACKS_SUCCESS',
      feedbacks: res.feedbacks
    })
  });
};
