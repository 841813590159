
import Guide from './guide';
import {connect} from 'react-redux'
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {fetchGuide, saveGuide, editNode, editNodeDone,
  fetchProductsLikeVarenummer, clearProducts, fetchInstructions, getIcons
} from '../../../redux/actions'

class GuideContainer extends Component {

  fetchAll(language) {
    this.props.editNodeDone();
    this.props.fetchGuide(language.name);
    this.props.fetchInstructions(language.name);
    this.props.getIcons();
  }

  componentWillMount () {
    this.fetchAll(this.props.language);
  }

  componentWillReceiveProps (props) {
    if (this.props.language.name !== props.language.name) {
      this.fetchAll(props);
    }
  }

  render () {
    return <Guide {...this.props} />
  }
}

const GuideConnector = connect((state) => {
  return {
    guide: state.guide,
    language: state.language,
    product: state.product,
    session: state.session,
    instruction: state.instruction,
    icons: state.icon.icons
  };
}, dispatch => {
  return bindActionCreators({
    fetchGuide,
    saveGuide,
    editNode,
    editNodeDone,
    fetchProductsLikeVarenummer,
    clearProducts,
    fetchInstructions,
    getIcons
  }, dispatch)
})(GuideContainer)

export default GuideConnector
