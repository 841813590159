import React, {Component} from 'react';
import classNames from 'classnames';
import { MiniButton } from './widgets';

export const TreeBranch = ({ node, onClick }) => {
  const name = node.name || 'Forsiden';
  return (
    <div onClick={onClick} className='tree-branch'>
      <span className='name'>{name}</span>
    </div>
  );
};

export class TreeNode extends Component {

  componentWillMount () {
    this.setState({open: true });
  }

  openEditor (b) {
    this.props.editNode(this.props.path);
  }

  render () {

    const {node, level} = this.props;
    if (level < 1 && !node.branches) {
      return (
        <div>
          <h3> Nytt tre </h3>
          <MiniButton onClick={e => this.openEditor(true)}>
            Start med å redigere roten.
          </MiniButton>
        </div>
      )
    }
    if (!node.branches) {
      return (
        <li className='branch-end'>
          <div className='name'>
            {node.name} ({(node.products && node.products.length) || 0})
          </div>
          <MiniButton onClick={e => this.openEditor(true)}>
            Rediger "{node.name}"
          </MiniButton>
        </li>
      );
    }

    const children = Object.keys(node.branches).map(b => {
      return (
        <div key={b}>
          <TreeNode
            path={this.props.path.concat(b)}
            editNode={this.props.editNode}
            node={node.branches[b]} level={level+1} />
        </div>
      );
    });

    const klass = classNames(['tree-node', {
      'open': this.state.open,
      'closed': !this.state.open
    }]);

    return (
      <div className={klass}>
        <TreeBranch node={node} onClick={e =>
          this.setState({ open: !this.state.open })
        } />
        <MiniButton onClick={e => this.openEditor(true)}>
          Rediger "{node.name||'Forsiden'}"
        </MiniButton>
        <ul className='tree-node-branches'>
          {children}
        </ul>
      </div>
    );
  }
}
