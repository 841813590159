import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {productSearch, fetchLanguage} from '../../redux/actions';
import Search from './search';

class SearchContainer extends Component {

  componentWillMount () {
    const {language} = this.props;
    if (!language.translation._key) {
      this.props.fetchLanguage(language.name);
    }
  }

  render () {
    return <Search {...this.props} />
  }
}

const SearchConnected = connect(state => {
  return { product: state.product, language: state.language };
}, dispatch => {
  return bindActionCreators({
    productSearch,
    fetchLanguage
  }, dispatch)
})(SearchContainer);

export default SearchConnected;
