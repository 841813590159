
const feedback = (state = {}, action) => {
  switch (action.type) {

    case 'SEND_FEEDBACK_BEGIN':
      return { ...state, beginAt: Date.now(), doneAt: null }

    case 'SEND_FEEDBACK_DONE':
      return { ...state, created: action.feedback, doneAt: Date.now() };

    case 'FEEDBACK_CLEAR':
      return Object.assign({}, state, {
        created: null, beginAt: null, doneAt: null
      });

    case 'FETCH_FEEDBACKS_BEGIN':
      return {...state, fetchFeedbacksAt: Date.now() };

    case 'FETCH_FEEDBACKS_SUCCESS':
      return {...state, feedbacks: action.feedbacks, fetchFeedbacksAt: null };

    default:
      return state;
  }
}

export default feedback;
