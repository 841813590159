import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {MiniButton} from '../guide/widgets';
import Im from 'immutable';
import ArrowUp from 'material-ui/svg-icons/navigation/arrow-upward';
import ArrowDown from 'material-ui/svg-icons/navigation/arrow-downward';
import Close from 'material-ui/svg-icons/navigation/close';

import './instruction.css';

const pseudoId = () => Math.floor(Math.random()*Date.now()).toString(36);

const cleanInstruction = instruction => {
  return Im.Map({
    _key: instruction.get('_key'),
    title: instruction.get('title'),
    ingress: instruction.get('ingress'),
    steps: instruction.get('steps')
  });
};

class Field extends Component {

  onBlur (e) {
    if (this.props.onUpdate) {
      this.props.onUpdate(e.target.value)
    } else {
      console.error('Field#onUpdate is not defined');
    }
  }

  render () {
    const {label, name } = this.props;
    const style = this.props.style;
    const id = 'field-' + pseudoId();
    return (
      <div className='field' style={style}>
        <label htmlFor={id}>{label}</label>
        {this.props.type === 'textarea' ? (
          <textarea id={id} name={name} style={{width: '90%', height: '5em'}}
            defaultValue={this.props.value}
            onBlur={e => this.onBlur(e) } />
        ) : (
          <input id={id} type="text" name={name} style={{width: '90%'}}
            defaultValue={this.props.value}
            onBlur={e => this.onBlur(e) } />
        )}
      </div>
    )
  }
}

class ImagePreview extends Component {

  componentWillMount () {
    this.setState({ hover: false })
  }

  render () {

    const {src, onChange, style} = this.props;

    if (!src) {

      const id = 'file-field-' + pseudoId();

      return (
        <div className='file-field'>
          <label htmlFor={id}>Last opp en fil</label>
          <input id={id} type="file" onChange={e => onChange(e)} />
        </div>
      )
    }

    const s3url = '//s3-eu-west-1.amazonaws.com/mft-festeguiden/' + src;

    return (
      <div style={{cursor: 'pointer'}} onClick={e => this.setState({ hover: !this.state.hover})}>
        {this.state.hover ? (
          <div style={{backgroundColor: '#ccc'}}>ok</div>
        ) : (
          <img style={style} src={s3url} alt="instruction-step" />
        )}
      </div>
    );
  }
}
export default class Instruction extends Component {

  onFileChange (evt,step) {

    const form = new FormData();
    form.append('file', evt.target.files[0]);
    form.append('instructionKey', this.props.instruction.get('_key'));
    form.append('step', step);

    this.props.uploadInstructionStepImage(
      this.props.session,
      form
    );
  }

  deleteStepAt (idx) {
    const instruction = this.props.instruction.deleteIn(['steps', idx]);
    this.save(instruction);
  }

  save (instruction) {

    if(this.props.instruction.equals(instruction)) {
      return;
    }

    const i = cleanInstruction(instruction).set('lang', this.props.language);

    if(i.get('_key')) {
      this.props.updateInstruction(this.props.session, i)
    } else {
      this.props.saveInstruction(
        this.props.session,
        Im.Map(i.delete('_key'))
      );
    }
  }

  render () {

    const {instruction} = this.props;

    if (!instruction) {
      return null; // do not dare to crash me!
    }

    return (
      <div className='instruction-edit' style={{maxWidth:'800px'}}>
        <h3>Bruksanvisning for: "{instruction.get('title')}"</h3>

        <div style={{margin: '1em 0'}}>
          <Link to="/admin/instructions/all">Tilbake</Link>
        </div>

        <Field key={`title.${instruction.get('_key')}`} name='title' label='Tittel' value={instruction.get('title')} onUpdate={value => {
          this.save(instruction.set('title', value));
        }} />

        <Field type='textarea' key={`ingress.${instruction.get('_key')}`} name='ingress' label='Ingress' value={instruction.get('ingress')} onUpdate={value => {
          this.save(instruction.set('ingress', value));
        }} />

        <h3>Instruksjoner</h3>

        <div className='steps'>
          {instruction.get('steps').map((step,idx) => {
            return (
              <div className='step' key={`step-${pseudoId()}`} style={{marginBottom: '2em', display: 'flex'}}>

                <Field type='textarea' label='Tekst' name={`step-${idx}`} value={step.get('ingress')} onUpdate={value => {
                  this.save(instruction.setIn(['steps', idx, 'ingress'], value));
                }} />

                <div className='fileupload'>
                  <ImagePreview src={step.getIn(['image','filename'])} style={{width: '150px'}} onChange={
                    e => this.onFileChange(e, idx)
                  } />
                </div>

                <div className='rearrange-order'>
                  <div className='ikon'>
                    <ArrowUp style={{color: 'white'}} onClick={e =>
                      this.props.moveInstructionStep(this.props.session, instruction.get('_key'), step.get('id'), -1)
                    }/>
                  </div>

                  <div className='ikon'>
                    <ArrowDown style={{color: 'white'}} onClick={e =>
                      this.props.moveInstructionStep(this.props.session, instruction.get('_key'), step.get('id'), 1)
                    } />
                  </div>

                  <div className='ikon red' onClick={e => { this.deleteStepAt(idx) }}>
                    <Close style={{color: 'white'}} />
                  </div>

                  <div className='clear'></div>
                </div>
              </div>
            )
          })}
        </div>

        <div>
          <MiniButton onClick={e =>
            this.props.addInstructionStep(this.props.session,
              instruction.get('_key'), 'Steg ' + (instruction.get('steps').size + 1))
          }>
            Legg til nytt steg
          </MiniButton>
        </div>
      </div>
    );
  }
}
