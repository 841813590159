
const initialState = {
  categories: [],
  category: undefined
};

const category = (state = initialState, action) => {
  switch (action.type) {

    case 'FETCH_CATEGORIES':
      return { ...state, categories: action.categories };

    case 'FETCH_CATEGORY':
      return { ...state, category: action.category };

    case 'PATCH_CATEGORY':
      return { ...state, category: action.category, error: null };

    case 'PATCH_CATEGORY_ERROR':
      return { ...state, error: action.error };

    default:
      return state
  }
}

export default category;
