
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import Products from './products';
import {fetchProductsLikeVarenummer} from '../../../redux/actions';

const ProductsContainer = connect((state) => {
  return { products: state.product.products, language: state.language };
}, dispatch => {
  return bindActionCreators({
    fetchProductsLikeVarenummer
  }, dispatch)
})(Products)

export default ProductsContainer;
