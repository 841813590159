
import Authenticate from './authenticate';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {authenticate, fetchCrumb, fetchToken,
  fetchUser} from '../../redux/actions';

const AuthenticateContainer = connect((state) => {
  return { session: state.session, language: state.language }
}, (dispatch) => {
  return bindActionCreators({
    authenticate,
    fetchCrumb,
    fetchToken,
    fetchUser
  }, dispatch)
})(Authenticate);

export default AuthenticateContainer
