
import React from 'react';
import I from 'immutable';
import EditField from './edit-field';

export default class Translation extends React.Component {

  state = {
    baseline: null,
  };

  static getDerivedStateFromProps (nextProps, prevState) {
    if (!prevState.baseline) {
      return { baseline: I.fromJS(nextProps.language.baseline) }
    }
    return null
  }

  render () {

    if (!this.state.baseline) {
      return null;
    }

    const {translation} = this.props.language;
    const {baseline} = this.state;
    const baselineSorted = baseline
    .sort((a,b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    })
    .map((v,k) => {
      return <EditField baseline={baseline} key={k} _key={k}
        value={translation[k]} onChange={(key, value) => {
          const t = {};
          t[key] = value;
          this.props.updateLanguage(this.props.session, this.props.language.name, t);
        }} />
    });

    return (
      <div>
        <h2>{translation.translation || 'Translation'}</h2>
        <div style={{display: 'flex', flexFlow: 'row wrap'}}>
          {baselineSorted.toOrderedSet()}
        </div>
      </div>
    )
  }
}
