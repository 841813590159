
const prefix  = '/api/v1';

export const uploadIconImage = (session, language, form) => dispatch => {
  fetch(`${prefix}/icons/${language}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: form
  })
  .then(res => res.json())
  .then((result) => {
    const {icon} = result;
    dispatch({
      type: 'UPLOAD_ICON_IMAGE_SUCCESS',
      icon
    });
    dispatch(getIcons());
  });
};

export const removeIconFromIcons = key => (dispatch, getState, data) => {
  const state = getState();
  const icons = Array.prototype.slice.call(state.icon.icons, 0);
  const idx = icons.findIndex(i => i._key === key);
  if (idx >= 0) {
    icons.splice(idx, 1);
  }
  return dispatch({
    type: 'REMOVE_ICON_FROM_ICONS',
    icons
  });
};

export const destroyIcon = (session, key) => (dispatch) => {

  fetch(`${prefix}/icons/${key}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    }
  })
  .then(res => {
    if (res.ok) {
      dispatch(removeIconFromIcons(key));
    }
  });
};

export const getIcons = () => dispatch => {
  fetch(`${prefix}/icons`)
  .then(result => result.json())
  .then(result => {
    const {icons} = result;
    dispatch({
      type: 'GET_ICONS_SUCCESS',
      icons
    });
  });
};

export const clearIcon = () => {
  return {
    type: 'CLEAR_ICON'
  }
};
