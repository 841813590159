import I from 'immutable';

export default I.fromJS([{
  name: 'Vekt lett',
  css: 'vekt-lett'
}, {
  name: 'Vekt middels',
  css: 'vekt-middels'
}, {
  name: 'Vekt tung',
  css: 'vekt-tung'
}, {
  name: 'Materiale betong',
  css: 'materiale-betong'
}, {
  name: 'Materiale gips',
  css: 'materiale-gips'
}, {
  name: 'Materiale lettbetong',
  css: 'materiale-lettbetong'
}, {
  name: 'Materiale tre',
  css: 'materiale-tre'
}, {
  name: 'Overflate gulv',
  css: 'overflate-gulv'
}, {
  name: 'Overflate tak',
  css: 'overflate-tak'
}, {
  name: 'Overflate vegg',
  css: 'overflate-vegg'
}, {
  name: 'Miljø innendørs',
  css: 'miljo-indoor'
}, {
  name: 'Miljø utendørs',
  css: 'miljo-outdoor'
}, {
  name: 'Forpakning plast',
  css: 'forpakning-plast'
}, {
  name: 'Blister pack indoor',
  css: 'blister-pack-indoor'
}, {
  name: 'Box indoor',
  css: 'box-indoor'
}, {
  name: 'Miljø Stainless',
  css: 'miljo-stainless'
}, {
  name: 'Plateanker',
  css: 'plateanker'
}, {
  name: 'Takanker med krok',
  css: 'takanker-med-krok'
}]);
