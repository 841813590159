
import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {uploadIconImage, getIcons, clearIcon, destroyIcon} from '../../../redux/actions';
import StyleCreator from './style-creator';

const StyleCreatorConnected = connect((state) => {
  return {
    language: state.language,
    session: state.session,
    icon: state.icon
  };
}, dispatch => {
  return bindActionCreators({
    uploadIconImage,
    getIcons,
    clearIcon,
    destroyIcon
  }, dispatch)
})(StyleCreator)

export default class Index extends Component {
  render () {
    return <StyleCreatorConnected />
  }
}
