
const I = require('immutable');

const initialState = {
  products: I.fromJS([]),
  productsByKeys: I.fromJS([]),
  importedProducts: I.fromJS([]),
  importInProgress: false
};

const product = (state = initialState, action) => {

  let error = null;

  switch (action.type) {

    case 'DESTROY_PRODUCT':
      return { ...state, product: null, products: I.fromJS([]), error };

    case 'FETCH_PRODUCT':
      return { ...state, product: I.fromJS(action.product), error };

    case 'PRODUCT_NOT_FOUND':
      return { ...state, error: 'not-found' }

    case 'CLEAR_PRODUCTS':
      return { ...state, products: I.fromJS([]) };
      
    case 'FETCH_PRODUCTS':
      return { ...state, products: I.fromJS(action.products), error };

    case 'FETCH_PRODUCTS_BY_KEYS':
      return { ...state, productsByKeys: I.fromJS(action.products), error };

    case 'CLEAR_PRODUCTS_BY_KEYS':
      return { ...state, productsByKeys: I.fromJS([]), error };

    case 'PRODUCT_IMPORT_BEGIN':
      return { ...state, importInProgress: true, error };

    case 'PRODUCT_IMPORT_END':
      return {
        ...state,
        importedProducts: I.fromJS(action.products),
        importInProgress: false,
        error
      };

    case 'PRODUCT_IMPORT_CLEAR':
      return { ...state, importedProducts: I.fromJS([]), error };

    default:
      return state
  }
}

export default product
