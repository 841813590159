import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {MftImage} from '../../../common/product-image';
import {Attribute} from './attribute';
export class ProductComponent extends Component {
  render () {
    const {product} = this.props;
    return (
      <div className='product'>
        <div className='product-image'>
          <MftImage pictureLink={product.get('picture_link')} style={{width: '100%'}} limit={2} />
        </div>
        <div className='product-meta'>
          <Attribute name="Varenummer" value={product.get('varenummer')} />
          <Attribute name="Varebeskrivelse" value={product.get('nobb_varebeskrivelse')} />
          <Attribute name="Lang beskrivelse" value={product.get('nobb_lang_varetekst')} />
        </div>
      </div>
    )
  }
}

ProductComponent.propTypes = {
  product: PropTypes.object.isRequired
};
