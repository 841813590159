import React from 'react';
import Branch from './branch';

export const BranchElements = ({ branches, path}) => {
  if (!branches) {
    return null;
  }
  const els = Object.keys(branches).map(key => {
    const url = '/guide/' + path.concat(key).join('--');
    const branch = branches[key];
    return <Branch key={key} branch={branch} url={url} />;
  });
  return <div>{els}</div>
};
