import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import './styles.css';

class CategoryCollection extends Component {

  componentWillMount () {
    this.props.fetchCategories();
  }

  render () {

    const categories = this.props.category.categories;

    const categoryElements = categories.map((c,idx) => {

      const count = c.productCount;
      return (
        <tr key={c._key}>
          <td>{c.category1}</td>
          <td>{c.category2}</td>
          <td>
            <Link to={`/admin/category/${c._key}`}>{c.category3}</Link>
          </td>
          <td>{count}</td>
        </tr>
      );
    });
    return (
      <div>
        <h3>Kategori-oversikt</h3>
        <table className='table'>
          <thead style={{textAlign: 'left'}}>
            <tr>
              <th>Kat 1</th>
              <th>Kat 2</th>
              <th>Kat 3</th>
              <th>Antall produkter</th>
            </tr>
          </thead>
          <tbody>
            {categoryElements}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CategoryCollection;
