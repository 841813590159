import React from 'react';

export const Attribute = ({ name, value}) => {

  if (!value) {
    return null;
  }

  return (
    <div className='attribute'>
      <div className='name'>{name}</div>
      <div className='value'>{value}</div>
    </div>
  )
};