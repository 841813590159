
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {pickNode} from './tree-utils';
import {fetchGuide, fetchProductsByKeys, clearProductsByKeys, fetchInstructions,
  fetchLanguage} from '../../redux/actions';
import Guide from './guide';

const pathToArray = (path) => {
  if (path) {
    return path.split('--').filter(s => s);
  } else {
    return [];
  }
}

/**
* Guide component has four dependencies:
* guide, path (Array), node (current node) and categories (when we are
* at an end-branch).
* We get the guide first, then we pick the current node from the tree based on
* path. When we have a node, we can fetch categories, if it contains the prop
* 'categoryKeys'. A little contrived this is ... bear with us ;-D
* It doesnt get easier down the line.
*/
export class GuideContainer extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const products = (nextProps.node && nextProps.node.products) || [];
    const productKeys = products.map(p => p._key);
    if (productKeys.length > 0) {
      return {productKeys};
    }
    return {productKeys: null};
  }

  state = {};

  componentDidMount () {
    this.fetchOrClearProducts()
  }

  fetchOrClearProducts () {
    if(this.state.productKeys && this.props.products.size < 1) {
      this.props.fetchProductsByKeys(
        this.state.productKeys
      );
    } else if (!this.state.productKeys) {
      this.props.clearProductsByKeys();
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.fetchOrClearProducts();
  }

  componentWillUnmount () {
    this.props.clearProductsByKeys();
  }

  render () {
    const {node, path, guide, instruction, products, pathname} = this.props;
    return <Guide pathname={pathname} translation={this.props.language.translation}
      products={products} guide={guide} path={path} instructions={instruction.instructions}
      node={node} />
  }
}

let currentLanguage = null;

const InitData = (props, ownProps) => {
  /**
  * Lang has been switched. Reload data for appropriate language.
  */
  if (currentLanguage !== props.language.name) {

    if (currentLanguage !== null) {
      props.history.push('/guide?lang=' + props.language.name);
    }

    props.fetchGuide(props.language.name);
    props.fetchLanguage(props.language.name);
    props.fetchInstructions(props.language.name);

    currentLanguage = props.language.name;
  }

  if (props.guide) {
    const path = pathToArray(props.match.params.path);
    try {
      const node = pickNode(props.guide.content, path);
      const propsCopy = Object.assign({}, props, {path, node});
      return <GuideContainer {...propsCopy} />
    } catch (err) {
      return (
        <div>
          <p>Path invalid :: {err.message}</p>
          <Link to="/guide">Back to safety</Link>
        </div>
      )
    }
  }
  return <div>Loading</div>
};

const GuideConnected = connect( (state, ownProps) => {
  const guide = state.guide && state.guide.guide;
  const instruction = state.instruction;
  const products = state.product.productsByKeys;
  return { language: state.language, pathname: ownProps.location.pathname,
    guide, instruction, products };
}, dispatch => {
  return bindActionCreators({
    fetchGuide,
    fetchLanguage,
    fetchInstructions,
    fetchProductsByKeys,
    clearProductsByKeys
  }, dispatch)
})(InitData)

export default GuideConnected;
