
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import bigLogo from  './assets/big-logo.svg';

const style = {
  main: {
    width: '350px',
    margin: '4em auto 0',
    fontSize: '16pt',
    textAlign: 'center'
  },
  num: {
    margin: '0 .5em 0 0',
    display: 'inline-block',
    color: 'red'
  },
  text: {
    display: 'inline-block',
  },
  logo: {
    marginTop: '2em',
    padding: '1em',
    backgroundColor: 'red',
    cursor: 'pointer'
  }
};

export default class FourOhFour extends Component {
  render () {
    return (
      <div style={style.main}>
        <div>
          Fant ikke '{this.props.match.url}'
        </div>
        <div>
          <img onMouseOver={e => console.log(e)}
            style={style.logo} src={bigLogo} alt='company logo' />
        </div>
        <div style={style.num}>
          404
        </div>
        <div>
          <Link to="/">Tilbake til sikkerhet</Link>
        </div>
      </div>
    )
  }
}
