
import prefix from './prefix';

export const setLanguage = language => dispatch => {
  dispatch(fetchLanguage(language));
  return dispatch({
    type: 'SET_LANGUAGE',
    language: language.toLowerCase()
  });
};

let languages = {};

export const fetchLanguage = language => dispatch => {

  if (languages[language]) {
    return dispatch({
      type: 'FETCH_LANGUAGE',
      translation: languages[language]
    });
  }

  fetch(prefix + '/language/'+language)
  .then(res => res.json())
  .then(res => {
    languages[language] = res.language;
    return dispatch({
      type: 'FETCH_LANGUAGE',
      translation: res.language
    });
  });
};

export const updateLanguage = (session, id, props) => dispatch => {

  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + session.token,
      'x-csrf-token': session.crumb
    },
    body: JSON.stringify({ language: props })
  };
  fetch(`${prefix}/language/${id}`, options)
  .then(res => res.json())
  .then(res => {
    languages = {};
    return dispatch({
      type: 'UPDATE_LANGUAGE_SUCCESS',
      translation: res.language
    });
  });
};

export const fetchLanguageBaseline = () => dispatch => {

  dispatch({
    type: 'FETCH_LANGUAGE_BASELINE_BEGIN',
    fetchLanguageBaselineAt: Date.now()
  });

  fetch(prefix + '/language-baseline')
  .then(res => res.json())
  .then(res => {
    return dispatch({
      type: 'FETCH_LANGUAGE_BASELINE_SUCCESS',
      baseline: res,
      fetchLanguageBaselineAt: null
    })
  });
}
