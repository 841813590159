import React from 'react';
import Category from './category';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchCategory, patchCategory} from '../../../redux/actions';

class CategoryContainer extends React.Component {

  componentWillMount () {
    const {id} = this.props.match.params;
    this.setState({ category: null });
    this.props.fetchCategory(id);
  }

  onChange (category) {
    this.setState({ category });
  }

  onSave (category) {
    const _category = {
      category: {
        name: category.name,
        description: category.description
      }
    };
    this.props.patchCategory(this.props.session, category._key, _category);
  }

  componentWillReceiveProps(props) {
    this.setState({
      category: props.category.category,
      error: props.category.error,
      response: props.category.response
    });
  }

  shouldComponentUpdate (props,state) {
    if (!props.category.category) {
      return false;
    }
    return true;
  }

  render () {
    return <Category category={this.state.category}
      onChange={c => this.onChange(c)} response={this.state.response} error={this.state.error}
      onSave={c => this.onSave(c)} />
  }
}

const ConnectedCategory = connect((state) => {
  return {
    category: state.category,
    session: state.session
  };
}, (dispatch) => {
  return bindActionCreators({
    fetchCategory,
    patchCategory
  }, dispatch)
})(CategoryContainer);

export default ConnectedCategory;
