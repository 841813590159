const prefix = '/api/v1';

export const fetchToken = () => {
  const token = localStorage.getItem('token');
  return {
    type: 'FETCH_TOKEN',
    token
  };
};

export const fetchUser = (token) => dispatch => {

  dispatch({
    type: 'FETCH_USER_BEGIN'
  });

  return fetch(prefix + '/auth-check', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    credentials: 'include'
  })
  .then(res => res.json())
  .then(res => {
    if (res.statusCode && res.statusCode === 401) {
      localStorage.removeItem('token');
      return dispatch({
        type: 'FETCH_USER_ERROR',
        error: res.message
      });
    }
    return dispatch({
      type: 'FETCH_USER_SUCCESS',
      user: res
    })
  })
};

export const fetchCrumb = () => dispatch => {
  return fetch(prefix + '/crumb', {
    credentials: 'include'
  })
  .then(res => res.json())
  .then(res => dispatch({
    type: 'FETCH_CRUMB',
    crumb: res.crumb
  }));
};

export const logout  = () => dispatch => {
  localStorage.removeItem('token');
  return dispatch({
    type: 'LOGGED_OUT'
  });
};

export const authenticate = (crumb, user) => dispatch => {
  return fetch(prefix + '/authenticate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': crumb
    },
    credentials: 'include',
    body: JSON.stringify(user)
  })
  .then(res => res.json())
  .then(data => {
    if(data.error) {
      return dispatch({
        type: 'AUTHENTICATED_ERROR',
        error: data.message
      });
    } else {
      localStorage.setItem('token', data.token)
      return dispatch({
        type: 'AUTHENTICATED',
        token: data.token
      })
    }
  })
  .catch(err => {
    return dispatch({
      type: 'AUTHENTICATED_ERROR',
      error: err.message
    })
  })
};
