import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchLanguage, setLanguage} from './redux/actions';
import Search from './components/search';
import Splash from './components/splash';
import Guide from './components/guide';
import Admin from './components/admin';
import Category from './components/category';
import Authenticate from './components/authenticate';
import Instruction from './components/instruction';
import FourOhFour from './four-oh-four';
import 'react-tippy/dist/tippy.css';
import './app.css';
import TranslationContext from './translation-context';

const _Splash = ctx => (props) => {
  /**
  * Too terse to comprehend.
  */
  if (!props.match.params.language) {
    if (!ctx.props.language.translation._id) {
      ctx.props.fetchLanguage(ctx.props.language.name || 'no');
    }
  } else if(ctx.props.language.name !== props.match.params.language) {
    ctx.props.setLanguage(props.match.params.language);
  } else if (!ctx.props.language.translation._id){
    ctx.props.fetchLanguage(props.match.params.language);
  }
  return (
    <TranslationContext.Provider value={ctx.props.language.translation}>
      <Splash />
    </TranslationContext.Provider>
  )
};

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={_Splash(this)} />
          <Route exact path="/language/:language" component={_Splash(this)} />
          <Route exact path="/guide" component={Guide} />
          <Route path="/guide/:path" component={Guide} />
          <Route path="/category/:key" component={Category} />
          <Route path="/search" component={Search} />
          <Route path="/admin" component={Admin} />
          <Route path="/authenticate" component={Authenticate} />
          <Route path="/instruction/:key" component={Instruction} />
          <Route component={FourOhFour} />
        </Switch>
      </Router>
    );
  }
}

const Application = connect((state, ownProps) => {
  return { session: state.session, language: state.language };
}, dispatch => {
  return bindActionCreators({
    fetchLanguage,
    setLanguage
  }, dispatch)
})(App)

export default Application;
