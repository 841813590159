
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import InstructionsContainer from './container';
import {fetchInstructions, newInstruction, newInstructionStep, addInstructionStep,
  fetchInstruction, saveInstruction, updateInstruction, uploadInstructionStepImage,
  deleteInstruction, moveInstructionStep, deleteInstructionStep
} from '../../../redux/actions';

const InstructionsConnected = connect((state) => {
  return { instruction: state.instruction, language: state.language.name, session: state.session };
}, dispatch => {
  return bindActionCreators({
    addInstructionStep,
    deleteInstruction,
    deleteInstructionStep,
    moveInstructionStep,
    fetchInstructions,
    fetchInstruction,
    newInstruction,
    newInstructionStep,
    saveInstruction,
    updateInstruction,
    uploadInstructionStepImage
  }, dispatch)
})(InstructionsContainer);

export default InstructionsConnected;
