import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import classnames from 'classnames';
import './instruction-select.css';

export const InstructionSelect = ({ instructions, selected, className, onChange }) => {

  const klass = classnames('instruction-select', className);

  return (
    <div className={klass}>
      <ul>
        {instructions.map(instruction => {
          return (
            <li key={instruction.get('_id')}>
              <Checkbox label={instruction.get('title')} checked={
                selected.includes(instruction.get('_key'))
              } onCheck={e => {
                onChange(instruction, e.target.checked)
              }} />
            </li>
          )
        })}
      </ul>
    </div>
  );
}
