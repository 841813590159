import React, {Component} from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Skeleton from '../skeleton';

const AuthError = ({error}) => {

  const errorMap = {
    'Authentication failed': 'Autentiseringen feilet.',
    'no match': 'Det kan synes som du er logget inn fra ett annet sted. Logg inn på nytt.'
  };

  if (!error) {
    return null;
  }

  return (
    <p style={{color: 'red'}}>
      {errorMap[error] || error}
    </p>
  )
}

class Authenticate extends Component {
  componentWillMount () {
    this.setState({
      username: '',
      password: ''
    });
    this.props.fetchToken();
    this.props.fetchCrumb();
  }

  componentWillReceiveProps (props) {
    const {token, error, user, fetchingUserAt} = props.session;
    if (fetchingUserAt) {
      return;
    }
    if (token && !(error || user)) {
      this.props.fetchUser(props.session.token);
    }
  }

  requestAuthentication () {
    const user = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.authenticate(this.props.session.crumb, user);
  }

  handleChange (propName, value) {
    const user = {};
    user[propName] = value;
    this.setState(user);
  }

  render () {

    const style={ width: '80%', margin: '0 auto'};
    if (this.props.session.user) {
      return this.props.children
    }

    return (
      <Skeleton translation={this.props.language.translation}>
        <div style={style}>
          <h3>Logg inn</h3>

          <AuthError error={this.props.session.error} />

          <form>
            <div>
              <TextField hintText="Ditt brukernavn" floatingLabelText="Brukernavn"
                type="text" onChange={e => this.handleChange('username', e.target.value)} />
            </div>
            <div>
              <TextField hintText="Ditt passord" floatingLabelText="Passord" autoComplete="section-blue"
                type="password" onChange={e => this.handleChange('password', e.target.value)}/>
            </div>
            <div>
              <RaisedButton label="Send" primary={true} style={{margin: 12}}
                onClick={e => this.requestAuthentication()}/>
            </div>
          </form>
        </div>
      </Skeleton>
    );
  }
}
export default Authenticate
