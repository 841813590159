import React from 'react';
import { Tooltip } from 'react-tippy';
import {MftImage, imageUrls} from './product-image';
import Chip from 'material-ui/Chip';
import Avatar from 'material-ui/Avatar';

export const ProductChips = ({ products, onClick, onDelete }) => {

  if (!products) {
    return null;
  }

  const styles = {
    chip: {
      margin: 4,
    },
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    }
  };

  const chips = products.map(product => {

    const category = [
      <div style={{display: 'flex'}} key='details'>
        <div style={{flex: '1'}}>
          <MftImage pictureLink={product.get('picture_link')} style={{width:'80px'}} />
        </div>
        <div style={{flex: '2'}}>{product.get('nobb_lang_varetekst')}</div>
      </div>
    ]

    return (
      <Tooltip key={product.get('_id')}
        arrow={true}
        html={<div>{category}</div>}
        position="top"
        trigger="mouseenter">
        <Chip style={styles.chip} onRequestDelete={e => {
          onDelete(product)
        }} onClick={e => console.log(product.get('varenummer'))}>
          <Avatar src={
              imageUrls(product.get('picture_link'))[0]
            } />
          {product.get('varenummer')}
        </Chip>
      </Tooltip>
    );
  });
  return <div style={styles.wrapper}>{chips}</div>
};
