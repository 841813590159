import React from 'react';
import {PropTypes} from 'prop-types';
import feedback1 from '../../assets/feedback-1.svg'
import feedback1active from '../../assets/feedback-1-active.svg'
import feedback2 from '../../assets/feedback-2.svg'
import feedback2active from '../../assets/feedback-2-active.svg'
import feedback3 from '../../assets/feedback-3.svg'
import feedback3active from '../../assets/feedback-3-active.svg'
import './smileys.css';

export class Smileys extends React.Component {
  render () {
    const {active} = this.props;
    return (
      <div className='feedback-icons'>
        <div className='feedback-icon'>
          <img src={active === 1 ? feedback3active : feedback3} alt="smiley"
            onClick={e => {
              this.props.onChange(1)
            }} />
        </div>
        <div className='feedback-icon'>
          <img src={active === 2 ? feedback2active : feedback2} alt="smiley"
            onClick={e => {
              this.props.onChange(2)
            }} />
        </div>
        <div className='feedback-icon'>
          <img src={active === 3 ? feedback1active : feedback1} alt="smiley"
            onClick={e => {
              this.props.onChange(3)
            }} />
        </div>
      </div>
    )
  }
}

Smileys.propTypes = {
  onChange: PropTypes.func.isRequired,
  active: PropTypes.number
};
