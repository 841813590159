
import { combineReducers } from 'redux';
import session from './session';
import language from './language';
import category from './category';
import product from './product';
import guide from './guide';
import instruction from './instruction';
import feedback from './feedback';
import icon from './icon';

const mftApp = combineReducers({
  session,
  language,
  category,
  product,
  guide,
  instruction,
  feedback,
  icon
});

export default mftApp
