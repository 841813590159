import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import PropTypes from 'prop-types';
import {InputField} from './input-field';
import {$tr} from './utils';

const errorMessages = {
  filetypeNotAllowed: function (file) {
    return `Filtypen ${file.type} er ikke tillatt. Kun png og jpg er tillatt.`
  }
};

class UploadIcon extends React.Component {

  state = {
    showButton: false,
    errorMessage: ''
  }

  static getDerivedStateFromProps(props, state) {
    const icon = props.icon;
    return {
      showButton: !!(icon.get('title') && icon.has('orientation'))
    };
  }

  onIconFileUpload (e) {
    const file = e.target.files[0];

    if (this.props.validFileTypes.indexOf(file.type) < 0) {
      return this.setState({errorMessage: errorMessages.filetypeNotAllowed(file)})
    } else {
      this.setState({ errorMessage: ''})
    }

    this.props.onIconFileUpload(
      file,
      this.props.icon.get('orientation'),
      this.props.icon.get('title')
    );
    e.target.value = null;
  }

  render () {

    const {icon, language} = this.props;
    const {showButton} = this.state;

    return (
      <div className='jumbo-card'>

        <h3>
          {$tr(this.props.language, "icon_upload")}
        </h3>

        <div style={{margin: '10px 0', color: 'red'}}>
          {this.state.errorMessage}
        </div>

        <InputField style={{maxWidth: '300px'}} label={
          $tr(language, "title") + ' (' + $tr(language, "must_be_filled", { downcase: true}) + ')'
        } value={icon.get('title')} onChange={value => {
          this.props.onChange(icon.set('title', value))
        }} />

        <p>
          {$tr(language, "choose_left_or_right_side_of_button")}
        </p>

        <input type="file" name="ikon-fil" ref="iconFileField" style={{display: 'none'}}
          onChange={this.onIconFileUpload.bind(this)} />

        <div style={{marginBottom: '15px'}}>
          <Checkbox label={$tr(language, "left_side")} checked={icon.get('orientation') === 'left'}
            onCheck={e => {
              this.props.onChange(icon.set('orientation', 'left'))
            }} />

          <Checkbox label={$tr(language, "right_side")} checked={icon.get('orientation') === 'right'}
            onCheck={e => {
              this.props.onChange(icon.set('orientation', 'right'))
            }} />
        </div>

        <RaisedButton style={{
          display: showButton ? 'inline-block' : 'none'
        }} label={$tr(language, 'icon_add')} onClick={e => {
          this.refs.iconFileField.click()
        }} />
      </div>
    )
  }
}

UploadIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onIconFileUpload: PropTypes.func.isRequired,
  validFileTypes: PropTypes.array.isRequired
}

export { UploadIcon }
