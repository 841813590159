import React from 'react';

import feedback1 from '../../../assets/feedback-1-active.svg'
import feedback2 from '../../../assets/feedback-2-active.svg'
import feedback3 from '../../../assets/feedback-3-active.svg'


export const TextOrFallback = (props) => {
  return props.text || props.fallback
}

export const Rating = ({rating}) => {
  const style = {width: '25px'};
  if (rating === 1) {
    return <img style={style} src={feedback3} alt="smiley1" />
  }
  if (rating === 2) {
    return <img style={style} src={feedback2} alt="smiley2" />
  }
  if (rating === 3) {
    return <img style={style} src={feedback1} alt="smiley3" />
  }
}

export default class Feedbacks extends React.Component {
  headerStyle = {
    fontWeight: '600',
    margin: '5px 0 15px'
  };

  deleteFeedback (_key) {
    this.props.deleteFeedback(_key);
  }

  render () {
    const feedbacks = this.props.feedbacks.sort((a,b) => {
      const dateA = Date.parse(a.createdAt)
      const dateB = Date.parse(b.createdAt)
      if (dateA < dateB) return 1;
      if (dateA > dateB) return -1;
      return 0;
    })
    .map(feedback => {
      const date = new Date(Date.parse(feedback.createdAt))
      return (
        <div key={feedback._id} className={`feedback-${feedback._key}`} style={{display: 'flex', marginBottom: '15px'}}>
          <div style={{flex: 1}}>
            <Rating rating={feedback.rating}/>
          </div>
          <div style={{flex: 2, fontStyle: 'italic'}}>
            <TextOrFallback text={feedback.text} fallback={'Ingen tekst'} />
          </div>
          <div style={{flex: 2, fontSize: '0.8em'}}>
            {date.toLocaleString()}
          </div>
          <div style={{flex: 1}}>
            <button onClick={e => this.deleteFeedback(feedback._key)}>Slett</button>
          </div>
        </div>
      );
    });

    feedbacks.unshift(
      <div key="header" style={{display: 'flex'}}>
        <div style={Object.assign({}, {flex: 1}, this.headerStyle)}>
          Reaksjon
        </div>
        <div style={Object.assign({}, {flex: 2}, this.headerStyle)}>
          Tekst
        </div>
        <div style={Object.assign({}, {flex: 2}, this.headerStyle)}>
          Tidspunkt
        </div>
        <div style={Object.assign({}, {flex: 1}, this.headerStyle)}>
        </div>
      </div>
    );

    return (
      <div className='feedbacks'>
        <h3>Feedbacks ({this.props.feedbacks.length})</h3>
        <div className='items' style={{width: '800px'}}>{feedbacks}</div>
      </div>
    )
  }
}
