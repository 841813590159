import React, {Component} from 'react';

export default class EditField extends Component {

  state = { value: '' }

  style = {
    container: {
      width: '300px',
      backgroundColor: '#eee',
      border: '1px solid #ccc',
      borderRadius: '5px',
      padding: '0 10px',
      margin: '10px',
      boxShadow: '0px 1px 6px #ccc'
    },
    textarea: {
      padding: '5px 0 0 5px',
      border: '1px solid #ccc',
      width: '98.5%',
      fontSize: '1em'
    }
  };

  componentDidMount () {
    this.setState({ value: this.props.value });
  }

  render () {

    const {_key,baseline} = this.props;
    const {value} = this.state;
    const styleContainer = Object.assign({}, this.style.container, this.props.style);
    return (
      <div style={styleContainer}>
        <div style={{fontSize: '0.8em', fontStyle: 'italic', height: '50px', padding: '10px 0'}}>
          {baseline.get(_key)}
        </div>
        <textarea rows={5} style={this.style.textarea} name={_key} value={value} onChange={e => {
          const {value} = e.target;
          this.setState({ value });
        }} onBlur={e => {
          if (this.state.value !== this.props.value) {
            this.props.onChange(this.props._key, this.state.value);
          }
        }}/>
      </div>
    )
  }
}
